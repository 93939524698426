/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { FileUpload } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Autocomplete } from '@mui/material'
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import Caller from 'Apis/config/Caller';
import { POST, GET } from 'Apis/config/RequestType';
import { create_user, update_user, department_designation } from 'Apis/config/Url';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Footer from 'examples/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { department, demat_roles, designation, employment_status, employment_type } from 'Apis/Auth/auth';
import { useSnackbar } from 'notistack';
import { get_employee_user_details } from 'Apis/config/Url';
import { get_product } from 'Apis/config/Url';
import { reporting_manager } from 'Apis/config/Url';
import BackupIcon from '@mui/icons-material/Backup';
import { BASE_URL } from 'Apis/config/Url';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { related_employee_error } from 'Apis/config/Url';
import PhoneNumberMask from 'components/InputModifier';

const UpdateEmployeeErrors = (props) => {
    let url_string = window.location.href;
    let myParam = url_string.split('bulk-employee-upload/update-errors-')[1];

    const { enqueueSnackbar } = useSnackbar();
    const [dbrowsData, dbsetRowsData] = useState([]);
    const [values, setAllValues] = useState({
        employee_user_id: "",
        employee_id: "",
        first_name: '',
        last_name: "",
        email: "",
        mobile: "",
        official_mobile_number: "",
        extension: '',
        profile_pic: "",
        password: "",
        department_id: "",
        department: [],
        document: [],
        designation: [],
        designation_id: "",
        demat_roles: "",
        employment_type: "",
        employment_status: "",
        date_of_joining: "",
        reporting_manager_id: "",
        reporting_manager_val: "",
        reporting_manager: [],
        date_of_birth: "",
        gender: "Female",
        marital_status: "Unmarried",
        uan: "",
        pan: "",
        aadhar: "",
        assign_lead: false
    })
    const navigate = useNavigate();
    const [disabledSave, setDisabledSave] = useState();
    const [eFieldsDisable, setEFieldsDisable] = useState(false);
    const [errors, setAllErrors] = useState({
        employee_id: "",
        first_name: '',
        last_name: "",
        email: "",
        mobile: "",
        official_mobile_number: "",
        extension: '',
        profile_pic: "",
        password: "",
        department: "",
        designation: "",
        demat_roles: "",
        employment_type: "",
        employment_status: "",
        date_of_joining: "",
        reporting_manager: "",
        date_of_birth: "",
        gender: "",
        marital_status: "",
        uan: "",
        pan: "",
        aadhar: "",
        products: "",
    })

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);

            Caller(related_employee_error + "?id=" + myParam, "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    setAllValues((values) => ({
                        ...values,
                        employee_id: res.data.employee_id ? res.data.employee_id : "",
                        first_name: res.data.first_name ? res.data.first_name : "",
                        last_name: res.data.last_name ? res.data.last_name : "",
                        email: res.data.email ? res.data.email : "",
                        official_mobile_number: res.data.official_mobile_number ? res.data.official_mobile_number : "",
                        mobile: res.data.mobile ? res.data.mobile : "",
                        department_id: res.data.department ? res.data.department : "",
                        designation_id: res.data.designation ? res.data.designation : "",
                        demat_roles: res.data.demat_role ? res.data.demat_role : "",
                        employment_type: res.data.employement_type ? res.data.employement_type : "",
                        employment_status: res.data.employement_status ? res.data.employement_status : "",
                        date_of_joining: res.data.date_of_joining ? res.data.date_of_joining.split("-").reverse().join("-") : "",
                        reporting_manager_id: res.data.reporting_manager ? res.data.reporting_manager : "",
                        date_of_birth: res.data.date_of_birth ? res.data.date_of_birth.split("-").reverse().join("-") : "",
                        gender: res.data.gender ? res.data.gender : "Female",
                        marital_status: res.data.marital_status ? res.data.marital_status : "Single",
                        uan: res.data.uan ? res.data.uan : "",
                        pan: res.data.pan ? res.data.pan : "",
                        aadhar: res.data.aadhar ? res.data.aadhar : "",
                        password: res.data.password ? res.data.password : "",
                    }))
                    for (const [key, value] of Object.entries(res.error)) {
                        if (key == "demat_role") {
                            setAllErrors((values) => ({
                                ...values,
                                demat_roles: value,
                            }))
                        }
                        if (key == "employement_type") {
                            setAllErrors((values) => ({
                                ...values,
                                employment_type: value,
                            }))
                        }
                        if (key == "employement_status") {
                            setAllErrors((values) => ({
                                ...values,
                                employment_status: value,
                            }))
                        }
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }

            });

            Caller(department_designation + "?type=Department", "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setAllValues((values) => ({
                        ...values,
                        department: res.data
                    }));
                }
            });
            Caller(department_designation + "?type=Designation", "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    await setAllValues((values) => ({
                        ...values,
                        designation: res.data
                    }));
                }
            });




        }
    }, [])


    const autoGeneratePassword = (e) => {
        let length = 15,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+./-",
            retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        setAllValues((values) => ({
            ...values,
            password: retVal
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const textUpdate = (e) => {
        const re = /^[\-a-zA-Z0-9 ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const idUpdate = (e) => {
        console.log("Name = ",e.target.name);
        console.log("Value = ",e.target.value);
        const re = /^[\-a-zA-Z0-9 ]+$/;
        if (e.target.value == "") {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))

            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: "Employee Id Required."
            }))
        }

        if (re.test(e.target.value)) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))

            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }))
        }

    }
    const nameUpdate = (e) => {
        const re = /^[a-zA-Z ]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const fnameUpdate = (e) => {
        const re = /^[a-zA-Z ]+$/;
        if (e.target.value == '') {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))

            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: "First Name Required."
            }))
        }
        if (re.test(e.target.value)) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))

            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }))
        }

    }

    const lnameUpdate = (e) => {
        const re = /^[a-zA-Z ]+$/;
        if (e.target.value == '') {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))

            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: "Last Name Required."
            }))
        }
        if (re.test(e.target.value)) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))

            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }))
        }

    }

    const uanUpdate = (e) => {
        const re = /^[0-9]+$/;

        if (re.test(e.target.value) || e.target.value === "") {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }));
            if (e.target.value === '000000000000' || e.target.value.charAt(0) === '0') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "Invalid input."
                }));
            } else if (e.target.value.length < 12 && e.target.value != '') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "UAN Number Should be 12 Digits."
                }));
            } else {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: ""
                }));
            }
        } else {
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }));
        }
    };

    const panUpdate = (e) => {
        const { name, value } = e.target;
        const alphaNumericRe = /^[a-zA-Z0-9]*$/;
        const alphaRe = /^[a-zA-Z]+$/;
        const numRe = /^[0-9]+$/;

        let error = "";

        if (value === "") {
            setAllErrors((state) => ({
                ...state,
                [name]: ""
            }));
        } else if (!alphaNumericRe.test(value)) {
            error = "PAN Number must be alphanumeric!";
        } else if (alphaRe.test(value)) {
            error = "PAN Number cannot contain only alphabets!";
        } else if (numRe.test(value)) {
            error = "PAN Number cannot contain only numbers!";
        }

        setAllValues((state) => ({
            ...state,
            [name]: value
        }));
        setAllErrors((state) => ({
            ...state,
            [name]: error
        }));
    }

    const aadharUpdate = (e) => {
        const re = /^[0-9]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }))
            if (e.target.value === '000000000000' || e.target.value.charAt(0) === '0') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "Invalid input."
                }));
            } else if (e.target.value.length < 12 && e.target.value != '') {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: "Aadhaar Number Should be 12 Digits."
                }));
            } else {
                setAllErrors((state) => ({
                    ...state,
                    [`${e.target.name}`]: ""
                }));
            }
        } else {
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }));
        }
    }

    const passUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }
    const emailUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))
        setAllErrors((state) => ({
            ...state,
            [`${e.target.name}`]: ""
        }))
    }

    const officialEmailUpdate = (e) => {
        // Apply email regex
        // const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const re = /^[^\s@]+@[^\s@]+\.com$/
        const re = /^[^\s@]+@[^\s@]+\.(com|org|edu|net|gov|mil|co\.uk)$/

        const { name, value } = e.target;
        // Update value state
        setAllValues((state) => ({
            ...state,
            [name]: value
        }));
        // Update error state based on validation result
        if (value.trim() === "") {
            setAllErrors((state) => ({
                ...state,
                [name]: "email is not allowed to be empty"
            }));
        } else if (!re.test(value)) {
            setAllErrors((state) => ({
                ...state,
                [name]: "Invalid email address"
            }));
        } else {
            setAllErrors((state) => ({
                ...state,
                [name]: ""
            }));
        }
    };

    // const onMobileUpdate = (e) => {
    //     const re = /^[1-9][0-9]*$/;
    //     if (re.test(e.target.value) || (e.target.value === "")) {
    //         setAllValues((state) => ({
    //             ...state,
    //             [`${e.target.name}`]: e.target.value
    //         }))
    //     }
    //     setAllErrors((state) => ({
    //         ...state,
    //         [`${e.target.name}`]: ""
    //     }))
    // }

    const onMobileUpdate = (e) => {
        console.log("Name = ", e.target.name);
        console.log("Value = ", e.target.value);

        // const cleanPhoneNumber = e.target.value.replace(/[^\d]/g, '');
        // console.log("cleanPhoneNumber = ", cleanPhoneNumber);

        const re = /^[6-9]\d{9}$/;

        if (e.target.value === '') {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: "Mobile number is required."
            }));
        } else if (!re.test(e.target.value)) {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: "Invalid Official Mobile Number."
            }));
        } else {
            setAllValues((state) => ({
                ...state,
                [`${e.target.name}`]: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }));
        }
    };



    // const [state, setState] = useState('');
    // const handler = (event) => {
    //     // changing the state to the name of the key
    //   // which is pressed
    //   setState(event.key);
    // };

    // const isValidKey = (e) => {
    //     var charCode = e.keyCode || e.which;

    //     if (charCode == 8 || charCode == 46) {

    //         return true;
    //     }
    //     return false;
    // }

    // const validateQty = (e) => {
    //     var key = window.event ? event.keyCode : event.which;

    //     if (e.keyCode == 8 || e.keyCode == 46
    //         || e.keyCode == 37 || e.keyCode == 39) {
    //         return true;
    //     }
    //     else if (key < 48 || key > 57) {
    //         return false;
    //     }
    //     else return true;
    // };

    // const dematRoleUpdate = async (e) => {
    //     if (e.target.value != 1) {
    //         await Caller(reporting_manager + "?type=" + e.target.value, "", GET, false, true).then(async (res) => {
    //             if (res.success === true) {
    //                 setAllValues((values) => ({
    //                     ...values,
    //                     reporting_manager: res.data
    //                 }));
    //             }
    //         });
    //     }
    //     setAllValues((values) => ({
    //         ...values,
    //         demat_roles: e.target.value
    //     }));
    //     setAllErrors((state) => ({
    //         ...state,
    //         [`${e.target.name}`]: ""
    //     }))
    // }

    const dematRoleUpdate = async (e) => {

        setAllValues((values) => ({
            ...values,
            reporting_manager_val: "",
            reporting_manager_id: ''
        }));

        if (e.target.value !== 1) {
            await Caller(reporting_manager + "?type=" + e.target.value, "", GET, false, true)
                .then(async (res) => {
                    if (res.success === true) {
                        setAllValues((values) => ({
                            ...values,
                            reporting_manager: res.data
                        }));
                    }
                });
        }

        if (e.target.value === 5 && values.reporting_manager_val === "") {
            setAllErrors((errors) => ({
                ...errors,
                reporting_manager: "Reporting manager required!"
            }));
        } else {
            setAllErrors((errors) => ({
                ...errors,
                reporting_manager: ""
            }));
        }

        // Update the demat_roles regardless of the condition
        setAllValues((values) => ({
            ...values,
            demat_roles: e.target.value
        }));

        // Clear the error for the current target name
        setAllErrors((errors) => ({
            ...errors,
            [e.target.name]: ""
        }));
    };


    const addAdmin = async (event) => {
        event.target.classList.add("was-validated");
        setDisabledSave('disabled');
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('employee_id', values.employee_id);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('mobile', values.mobile);

        formData.append('official_mobile_number', values.official_mobile_number.replace(/[^\d]/g, ""));

        formData.append('password', values.password);
        formData.append('department', values.department_id);
        formData.append('designation', values.designation_id);
        formData.append('user_type', values.demat_roles);
        formData.append('employment_type', values.employment_type);
        formData.append('employment_status', values.employment_status);
        formData.append('date_of_joining', values.date_of_joining ?? "");
        // formData.append('reporting_manager', 1);
        formData.append('reporting_manager', values.reporting_manager_id);
        formData.append('date_of_birth', values.date_of_birth);
        formData.append('gender', values.gender);
        formData.append('is_logged_in', 1);
        formData.append('marital_status', values.marital_status);
        formData.append('uan', values.uan);
        formData.append('pan', values.pan);
        formData.append('import_data_id', myParam);
        formData.append('aadhar', values.aadhar);
        formData.append('assign_lead', values.assign_lead);
        formData.append('document', dbrowsData ? JSON.stringify(dbrowsData) : '');

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            // There are errors
            const errorKeys = Object.keys(errors);
            for (let i = 0; i < errorKeys.length; i++) {
                const key = errorKeys[i];
                // Access the value corresponding to each key
                const value = errors[key];

                // Do whatever checks you need with the key-value pair
                if (key === 'employement_type' || key === 'employement_status') {
                    // Set "" for employement_type and employement_status keys
                    setAllErrors((values) => ({
                        ...values,
                        [key]: "",
                    }));
                } else {
                    // For other error keys, update the state dynamically
                    setAllErrors((values) => ({
                        ...values,
                        [key]: errors[key],
                    }));
                }
            }
        }

        await Caller(create_user + "?user_type=employee", formData, POST, false, true).then((res) => {
            if (res.success === true) {
                enqueueSnackbar(`Employee "added" successfully!`, { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                navigate("/bulk-employee-upload");
            }
            else if (res.success === false) {
                setDisabledSave('');
                // for (const [key, value] of Object.entries(res.error)) {
                //     setAllErrors((values) => ({
                //         ...values,
                //         [`${key}`]: value,
                //     }))
                // }

                for (const [key, value] of Object.entries(res.error)) {
                    if (key === 'date_of_birth') {
                        setAllErrors((state) => ({
                            ...state,
                            [`date_of_birth`]: `date_of_birth must be less than or equal to "2009-12-31"`
                        }))
                    } else if (key === 'user_type') {
                        setAllErrors((state) => ({
                            ...state,
                            [`demat_roles`]: "Internal Designation is required"
                        }))
                    } else {
                        setAllErrors((values) => ({
                            ...values,
                            [`${key}`]: value,
                        }))
                    }
                }

                enqueueSnackbar(`Employee not added, Please resolve errors.`, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

            }
        })
    }

    const dojHandler = (e) => {
        let doj = e.target.value;
        let dob = values.date_of_birth;

        if (dob) {
            if (doj < dob) {
                setAllValues((values) => ({
                    ...values,
                    date_of_joining: e.target.value
                }));
                setAllErrors((state) => ({
                    ...state,
                    date_of_birth: "Date of birth must be less than date of joining!"
                }));
            } else {

                setAllValues((values) => ({
                    ...values,
                    date_of_joining: e.target.value
                }));
            }
        } else {
            setAllValues((values) => ({
                ...values,
                date_of_joining: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                [`${e.target.name}`]: ""
            }))
        }

    }

    const dobHandler = (e) => {
        let doj = values.date_of_joining;
        let dob = e.target.value;

        const cutoffDate = '2010-01-01';

        if (doj == "") {
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: ""
            }));
        }
        else if (dob < cutoffDate && dob > doj) {
            // If date of birth is greater than January 1, 2010
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: "Date of birth must be less than date of joining!"
            }));
        } else if (dob > doj) {
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: "Date of birth cannot be greater than January 1, 2010."
            }));
        } else {
            // If date of birth is valid
            setAllValues((values) => ({
                ...values,
                date_of_birth: e.target.value
            }));
            setAllErrors((state) => ({
                ...state,
                date_of_birth: ""
            }));
        }
    }

    const selectReportingManager = (e, newValue, clickType) => {
        console.log("NEW VALUE error = ", newValue);
        if (clickType == 'clear') {
            setAllValues((values) => ({
                ...values,
                reporting_manager_val: "",
                reporting_manager_id: ''
            }));
            setAllErrors((state) => ({
                ...state,
                reporting_manager: ""
            }))
        } else {
            let data = values.reporting_manager?.find(o => o.name == newValue);
            setAllValues((values) => ({
                ...values,
                reporting_manager_val: newValue ? newValue : "",
                reporting_manager_id: data ? data.employee_user_id : ''
            }));
            setAllErrors((state) => ({
                ...state,
                reporting_manager: ""
            }))
        }

    }

    const assignLeadHandler = (e) => {
        setAllValues((values) => ({
            ...values,
            assign_lead: e.target.checked
        }))
    }

    // const allTrue = () => {
    //     if (
    //         (values.employee_id &&
    //             values.first_name &&
    //             values.last_name &&
    //             values.email &&
    //             values.official_mobile_number &&
    //             values.employment_status &&
    //             values.first_name &&
    //             values.last_name &&
    //             values.department_id &&
    //             values.designation_id &&
    //             values.employment_type &&
    //             values.demat_roles) ||
    //         (values.demat_roles == '5' && values.reporting_manager_id)
    //     ) {
    //         return false
    //     } else {
    //         return true
    //     }
    // }

    const allTrue = () => {
        if (
            ((values.employee_id && values.employee_id != '') &&
                (values.first_name && values.first_name != '') &&
                (values.last_name && values.last_name != '') &&
                (values.email && values.first_name != '') &&
                (values.official_mobile_number && values.official_mobile_number != '') &&
                (values.employment_status && values.employment_status != '') &&
                (values.first_name && values.first_name != '') &&
                (values.last_name && values.last_name != '') &&
                (values.department_id && values.department_id != '') &&
                (values.designation_id && values.designation_id != '') &&
                values.employment_type && values.employment_type != '' &&
                (values.demat_roles && values.demat_roles != '')) ||
            (values.demat_roles == '5' && values.reporting_manager_id)
        ) {
            return false
        } else {
            return true
        }
    }

    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h4" color="white">
                                    Update Employee Errors
                                </MDTypography>
                            </MDBox>
                            <MDBox p={3}>
                                <Box
                                    component="form"
                                    onSubmit={addAdmin}
                                    sx={{
                                        '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                                        '.role-select-custom': { pt: 1.5, pb: 1.5 },
                                        '.add-label': { pb: 1 },
                                        '.radio-button-custom': { fontSize: 'small' }
                                    }}
                                    noValidate
                                >
                                    <Grid container spacing={{ xs: 5, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }} pl={4}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Basic Information
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Employee Id <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='employee_id'
                                                value={values.employee_id}
                                                onChange={idUpdate}
                                                error={!!errors.employee_id}
                                                helperText={errors.employee_id === "" ? '' : errors.employee_id}
                                                inputProps={{
                                                    maxLength: 100
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>First Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='first_name'
                                                value={values.first_name}
                                                onChange={fnameUpdate}
                                                error={!!errors.first_name}
                                                helperText={errors.first_name === "" ? '' : errors.first_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Last Name <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='last_name'
                                                value={values.last_name}
                                                onChange={lnameUpdate}
                                                error={!!errors.last_name}
                                                helperText={errors.last_name === "" ? '' : errors.last_name}
                                                inputProps={{
                                                    maxLength: 50
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Official Email ID <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="email"
                                                value={values.email}
                                                onChange={officialEmailUpdate}
                                                error={!!errors.email}
                                                helperText={errors.email === "" ? '' : errors.email}
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                                type="email"
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Official Mobile Number<sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="official_mobile_number"
                                                value={values.official_mobile_number}
                                                onChange={onMobileUpdate}
                                                // onChange={isValidKey}
                                                // onkeydown={isValidKey}
                                                // onKeyPress={(e) => handler(e)}
                                                // InputProps={{
                                                //     inputComponent: PhoneNumberMask,
                                                // }}
                                                inputProps={{
                                                    maxLength: 10,
                                                    minLength: 10
                                                }}
                                                error={!!errors.official_mobile_number}
                                                helperText={errors.official_mobile_number === "" ? '' : errors.official_mobile_number}
                                                type="phone"
                                            // disabled={eFieldsDisable}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Password</InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="password"
                                                value={values.password}
                                                onChange={passUpdate}
                                                error={!!errors.password}
                                                helperText={errors.password === "" ? '' : errors.password}
                                                inputProps={{
                                                    minLength: 8
                                                }}
                                            />
                                            <Grid item xs={12} sm={12} md={12}
                                                sx={{ textAlign: 'right', marginRight: "5%" }}
                                            ><Button onClick={autoGeneratePassword}>Auto Generate Password</Button>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Work Information
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Department <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="department"
                                                variant="standard"
                                                defaultValue={values.department_id}
                                                value={values.department_id}
                                                error={!!errors.department}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        department_id: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {values.department?.map((value, key) => {
                                                    return <MenuItem key={key + '_department'} value={value.id} >{value.name}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.department === "" ? '' : errors.department}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>External Designation <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="designation"
                                                variant="standard"
                                                defaultValue={values.designation_id}
                                                value={values.designation_id}
                                                error={!!errors.designation}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        designation_id: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {values.designation?.map((value, key) => {
                                                    return <MenuItem key={key + '_designation'} value={value.id} >{value.name}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.designation === "" ? '' : errors.designation}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Internal Designation <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="demat_roles"
                                                variant="standard"
                                                defaultValue={values.demat_roles}
                                                error={!!errors.demat_roles}
                                                value={values.demat_roles}
                                                onChange={dematRoleUpdate}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {demat_roles?.map((value, key) => {
                                                    return <MenuItem key={key + '_roles'} value={key + 1} >{value}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.demat_roles === "" ? '' : errors.demat_roles}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Employment Type <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="employment_type"
                                                variant="standard"
                                                defaultValue={values.employment_type}
                                                value={values.employment_type}
                                                error={!!errors.employment_type}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        employment_type: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        // employment_type: "ABCD"
                                                        employment_type: ""
                                                        // employment_type: ""
                                                    }))
                                                }}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {employment_type?.map((value, key) => {
                                                    return <MenuItem key={key + '_employment_type'} value={value} >{value}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.employment_type === "" ? '' : errors.employment_type}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Employment Status <sup style={{ color: "red" }}>*</sup></InputLabel>
                                            <Select
                                                required
                                                displayEmpty
                                                style={{ width: "90%", height: 45 }}
                                                name="employment_status"
                                                variant="standard"
                                                defaultValue={values.employment_status}
                                                value={values.employment_status}
                                                error={!!errors.employment_status}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        employment_status: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        [`${e.target.name}`]: ""
                                                    }))
                                                }}
                                            >
                                                <MenuItem value="">--Select--</MenuItem>
                                                {employment_status?.map((value, key) => {
                                                    return <MenuItem key={key + '_emp_status'} value={value} >{value}</MenuItem>;
                                                })}
                                            </Select>
                                            <FormHelperText error>{errors.employment_status === "" ? '' : errors.employment_status}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Date of Joining</InputLabel>
                                            <TextField
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name='date_of_joining'
                                                value={values.date_of_joining}
                                                sx={{
                                                    '.MuiInputBase-input': { paddingTop: "15px", paddingBottom: "14.5px" }
                                                }}
                                                error={!!errors.date_of_joining}
                                                helperText={errors.date_of_joining === "" ? '' : errors.date_of_joining}
                                                onChange={dojHandler}

                                                type="date"
                                                inputProps={{
                                                    // min: '2024-04-03', // Set min attribute to a static date
                                                    // max: '2024-04-03' // Set max attribute to a static date
                                                }}
                                                disabled={eFieldsDisable}
                                            />
                                        </Grid>

                                        {/* Assign Lead Checkbox */}
                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={values.assign_lead} onChange={assignLeadHandler} />} label={<span style={{ color: '#7B809A', fontWeight: 'normal' }}>Assign Lead</span>} />
                                            </FormGroup>
                                        </Grid>

                                        {/* {(values.demat_roles != 1) && <><Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Hierarchy  Information
                                            </MDTypography>

                                        </Grid>

                                            <Grid item xs={6} sm={6} md={6}>
                                                <InputLabel className='add-label'>Reporting Manager</InputLabel>
                                                <Select
                                                    required
                                                    displayEmpty
                                                    style={{ width: "90%", height: 45 }}
                                                    name="reporting_manager"
                                                    variant="standard"
                                                    defaultValue={values.reporting_manager_id}
                                                    value={values.reporting_manager_id}
                                                    error={!!errors.reporting_manager}
                                                    onChange={(e) => {

                                                        setAllValues((values) => ({
                                                            ...values,
                                                            reporting_manager_id: e.target.value
                                                        }));
                                                        setAllErrors((state) => ({
                                                            ...state,
                                                            [`${e.target.name}`]: ""
                                                        }))
                                                    }}
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    {values.reporting_manager?.map((value, key) => {
                                                        return <MenuItem key={key + '_manager'} value={value.employee_user_id} >{value.name}</MenuItem>;
                                                    })}
                                                </Select>
                                                <FormHelperText error>{errors.reporting_manager === "" ? '' : errors.reporting_manager}</FormHelperText>
                                            </Grid></>} */}






                                        {(values.demat_roles != 1 && values.demat_roles != "") && <><Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Hierarchy  Information
                                            </MDTypography>

                                        </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <InputLabel className='add-label'>Reporting Manager</InputLabel>
                                                <Autocomplete
                                                    value={values.reporting_manager_val}
                                                    style={{ width: "90%" }}
                                                    onChange={selectReportingManager}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" />
                                                    )}
                                                    error={!!errors.reporting_manager}
                                                    options={values.reporting_manager?.map((value) => {
                                                        return value.name ?? "";
                                                    })}
                                                />
                                                {/* <Select
                                                    required
                                                    displayEmpty
                                                    style={{ width: "90%", height: 45 }}
                                                    name="reporting_manager"
                                                    variant="standard"
                                                    defaultValue={values.reporting_manager_id}
                                                    value={values.reporting_manager_id}
                                                    error={!!errors.reporting_manager}
                                                    onChange={(e) => {

                                                        setAllValues((values) => ({
                                                            ...values,
                                                            reporting_manager_id: e.target.value
                                                        }));
                                                    }}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    {values.reporting_manager?.map((value, key) => {
                                                        return <MenuItem key={key + '_manager'} value={value.employee_user_id} >{value.name}</MenuItem>;
                                                    })}
                                                </Select> */}
                                                <FormHelperText error>{errors.reporting_manager === "" ? '' : errors.reporting_manager}</FormHelperText>
                                            </Grid></>}


                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Personal  Information
                                            </MDTypography>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Date of Birth</InputLabel>
                                            <TextField
                                                // required
                                                variant="standard"
                                                value={values.date_of_birth}
                                                sx={{
                                                    '.MuiInputBase-input': { paddingTop: "15px", paddingBottom: "14.5px" }
                                                }}
                                                style={{ width: "90%" }}

                                                onChange={dobHandler}
                                                error={!!errors.date_of_birth}
                                                helperText={errors.date_of_birth === "" ? '' : errors.date_of_birth}
                                                name='date_of_birth'
                                                type="date"

                                                inputProps={{
                                                    max: '2010-01-01', // Set min attribute to a static date
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}></Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Gender <sup style={{ color: "red" }}>*</sup></FormLabel>
                                            <RadioGroup
                                                row
                                                name="row-radio-buttons-group"
                                                value={values.gender}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        gender: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        gender: ""
                                                    }))
                                                }}
                                                error={!!errors.gender}
                                            >
                                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                <FormControlLabel
                                                    value="Other"
                                                    control={<Radio />}
                                                    label="Other"
                                                />
                                            </RadioGroup>
                                            <FormHelperText error>{errors.gender === "" ? '' : errors.gender}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Marital Status <sup style={{ color: "red" }}>*</sup></FormLabel>
                                            <RadioGroup
                                                row
                                                name="row-radio-buttons-group"
                                                value={values.marital_status}
                                                onChange={(e) => {
                                                    setAllValues((values) => ({
                                                        ...values,
                                                        marital_status: e.target.value
                                                    }));
                                                    setAllErrors((state) => ({
                                                        ...state,
                                                        marital_status: ""
                                                    }))
                                                }}
                                                error={!!errors.marital_status}
                                            >
                                                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                                                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                                            </RadioGroup>
                                            <FormHelperText error>{errors.marital_status === "" ? '' : errors.marital_status}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography className='background-image-gradient' variant="h4">
                                                Identity Information
                                            </MDTypography>
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>UAN</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="uan"
                                                value={values.uan}
                                                onChange={uanUpdate}
                                                error={!!errors.uan}
                                                helperText={errors.uan === "" ? '' : errors.uan}
                                                inputProps={{
                                                    maxLength: 12
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>PAN</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="pan"
                                                value={values.pan.toLocaleUpperCase()}
                                                onChange={panUpdate}
                                                error={!!errors.pan}
                                                helperText={errors.pan === "" ? '' : errors.pan}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Aadhaar</InputLabel>
                                            <TextField
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="aadhar"
                                                value={values.aadhar}
                                                onChange={aadharUpdate}
                                                error={!!errors.aadhar}
                                                helperText={errors.aadhar === "" ? '' : errors.aadhar}
                                                inputProps={{
                                                    maxLength: 12
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <MDTypography variant="h4" className='background-image-gradient'>
                                                Contact Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <InputLabel className='add-label'>Mobile</InputLabel>
                                            <TextField
                                                required
                                                id=""
                                                variant="standard"
                                                style={{ width: "90%" }}
                                                name="mobile"
                                                value={values.mobile}
                                                onChange={onMobileUpdate}
                                                error={!!errors.mobile}
                                                helperText={errors.mobile === "" ? '' : errors.mobile}
                                                inputProps={{
                                                    maxLength: 10,
                                                    minLength: 10
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <DialogActions
                                        sx={{
                                            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                                            marginTop: "5%",
                                            marginBottom: "0%",
                                            width: "100%",
                                            borderRadius: '0.5rem',
                                            '.action-button': { width: 120 }
                                        }}
                                    >
                                        {allTrue() && <MDTypography variant="h4" color="black" alignItems="center" style={{ color: "#F44335", marginRight: "20px" }}>All mandatory fields have not been provided. Please verify.</MDTypography>}
                                        <MDButton color='success' className="action-button" type="submit" disabled={allTrue()} >Update</MDButton>
                                        <MDButton color='warning' className="action-button" onClick={() => navigate("/bulk-employee-upload")}>Cancel</MDButton>
                                    </DialogActions>
                                </Box>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >

    )
}

export default UpdateEmployeeErrors