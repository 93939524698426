/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, DialogActions, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import Caller from 'Apis/config/Caller'
import { POST } from 'Apis/config/RequestType'
import { GET } from 'Apis/config/RequestType'
import { update_email_type } from 'Apis/config/Url'
import { get_email_type } from 'Apis/config/Url'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { TINYMCE_KEY } from 'Apis/config/ResponseHandler';
import Cookies from "js-cookie";

const EmailTemplates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [values, setAllValues] = useState({
    email_value: [],
    email_value_id: "",
    subject: "",
    body: ""
  })
  const [errors, setAllErrors] = useState({
    subject: ""
  })

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const userTypeFromCookie = Cookies.get('auth_usertype');
    setUserType(userTypeFromCookie);
    if (isInitialRender) {
      setIsInitialRender(false);
      Caller(get_email_type, "", GET, false, true).then(async (res) => {
        if (res.success === true) {

          await setAllValues((values) => ({
            ...values,
            email_value: res.data.data,
            email_value_id: res.data.data[0].email_type_id,
            subject: res.data.data[0].subject,
            body: res.data.data[0].body
          }));
        }
      });
    }
  }, [])

  const textUpdate = (e) => {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (re.test(e.target.value) || (e.target.value === "")) {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value
      }))
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: ''
    }));
  }

  const textValue = (content, editor) => {
    setAllValues((state) => ({
      ...state, body: content
    }));
  };

  const handleEmailTypeChange = async (e) => {
    let desc = values.email_value.find(o => o.id === e.target.value);
    await setAllValues((values) => ({
      ...values,
      email_value_id: e.target.value,
      subject: desc ? desc.subject : "",
      body: desc ? desc.body : "",
    }));
    // await setFirmDetails({ option: e.target.value });
  }
  const allTrue = () => {

    if ((values.subject && values.body && errors.subject === "") && (userType == '1' || userType == '2' || userType == '3')) {
      return false
    } else {
      return true
    }
  }
  const saveTemplate = async (event) => {
    event.target.classList.add("was-validated");
    event.preventDefault();
    event.stopPropagation();
    if (!event.target.checkValidity()) {
      return false;
    }


    const formData = new FormData();
    formData.append("email_type_id", values.email_value_id);
    formData.append("subject", values.subject);
    formData.append("body", values.body);
    await Caller(update_email_type, formData, POST, false, true).then((res) => {
      if (res.success === true) {
        enqueueSnackbar('Email Template updated successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
      }
      else if (res.success === false) {
        for (const [key, value] of Object.entries(res.message['errors'])) {
          setAllErrors((values) => ({
            ...values,
            [`${key}`]: value,
          }))
        }
      }
    })
      .catch(error => { alert(JSON.stringify(error.message)); })
  }

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}
        component="form"
        onSubmit={saveTemplate}
        sx={{
          '.role-select-custom': { width: "40%" },
          '.add-label': { pb: 1 }
        }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h4" color="white">
                  Email Templates
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Email Type <sup style={{ color: "red" }} >*</sup></InputLabel>
                    <FormControl className='role-select-custom'>
                      <Select
                        // IconComponent={() => (
                        //     <ExpandMore className="dropdown-arrow" />
                        // )}
                        style={{ height: 45 }}
                        displayEmpty
                        defaultValue={values.email_value_id}
                        value={values.email_value_id}
                        onChange={handleEmailTypeChange}

                      >
                        {values.email_value?.map((e, key) => {
                          return <MenuItem key={key} value={e.id} >{e.email_value}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Email Subject <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                      required
                      id="outlined-required"
                      name='subject'
                      value={values.subject}
                      error={errors.subject}
                      disabled={userType === '4' || userType === '5'}
                      helperText={errors.subject === "" ? '' : errors.subject}
                      style={{ width: "40%" }}
                      onChange={textUpdate}
                      InputProps={{
                        inputProps: {
                          maxLength: 250, minLength: 1
                        }
                      }}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel className='add-label'>Email Body <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <Editor
                      id="validationCustom03"
                      apiKey={TINYMCE_KEY}
                      required
                      name="body"
                      value={values.body}
                      disabled={userType === '4' || userType === '5'}
                      init={{
                        height: 500,
                        plugins: "image code",
                        directionality: "ltr",
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                      }}
                      onEditorChange={textValue}
                    />
                  </Grid>
                </Grid>
                <DialogActions
                  sx={{
                    background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                    marginTop: "5%",
                    marginBottom: "0%",
                    width: "100%",
                    borderRadius: '0.5rem',
                    '.action-button': { width: 100 }
                  }}
                >
                  <MDButton color='success' disabled={allTrue()} className="action-button" type="submit">Update</MDButton>
                </DialogActions>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EmailTemplates