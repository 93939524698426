
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import MDButton from 'components/MDButton';
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { lead_call_summary } from 'Apis/config/Url';
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { useEffect } from 'react';
import { PUT } from 'Apis/config/RequestType';
import { leadSubstagesForCall } from 'Apis/Auth/auth';
import { call_lead } from 'Apis/config/Url';

import { permissions } from "Apis/Auth/auth";
import { RIGHTS } from "Apis/config/ResponseHandler";
import { TINYMCE_KEY } from 'Apis/config/ResponseHandler';
import Cookies from "js-cookie";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "15vw",
        },
    },
};

const CallSummary = (props) => {
    let rights = Cookies.get(RIGHTS) ?? "";
    let rightsArray = rights.split(',') ?? [];
    let todayDate = new Date();
    const [date_time, setDateTimeValue] = useState(todayDate.toISOString().substring(0, 16));
    const [lead_sub_status, setLeadSubStatus] = useState("Open");
    const [disabledSave, setDisabledSave] = useState();
    const [callDescriptionRaw, setCallDescriptionRaw] = useState();
    const [showRecordingButton, setShowRecordingButton] = useState(props.editData.call_description_raw ? true : false);

    const handleDateChange = (event) => {
        setDateTimeValue(event.target.value);
    };

    const [editorValues, setEditorValues] = useState({
        body: ""
    })

    const textValue = (content, editor) => {
        setEditorValues((state) => ({
            ...state, body: content
        }));
    };

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.editData) {
                setDateTimeValue(props.editData.scheduled_time_date ? props.editData.scheduled_time_date.substring(0, 16) : todayDate.toISOString().substring(0, 16));
                setEditorValues((values) => ({
                    ...values,
                    body: props.editData.description ?? ""
                }))
            }
            if (props.steps) {
                switch (props.steps) {
                    case "Open":
                        setLeadSubStatus("Open");
                        break;
                    case "Callback":
                        setLeadSubStatus("Callback");
                        break;
                    case "Not Reachable":
                        setLeadSubStatus("Not Reachable");
                        break;
                    case "Interested":
                        setLeadSubStatus("Interested");
                        break;
                    default:
                        break;
                }
            }
        }
    }, [])

    const callRecording = async () => {
        let AccountSid, RecordingSid, CallData;
        CallData = JSON.parse(props.editData.call_description_raw)
        const formData = new FormData();
        formData.append('callSid', CallData.callSid);
        await Caller(call_lead, formData, POST, false, true).then((res) => {
            if (res.success == true) {
                AccountSid = res.data[0].accountSid;
                RecordingSid = res.data[0].sid;
            }
        })
        await fetch(`https://api.twilio.com/2010-04-01/Accounts/${AccountSid}/Calls/${CallData.callSid}/Recordings/${RecordingSid}.mp3`)
            .then(res => res.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", `Recording ${props.updateData.first_name.toUpperCase()}`);
                document.body.appendChild(link);
                link.click();
            })
    };

    const submitConversation = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        setDisabledSave('disabled');
        if (!event.target.checkValidity()) {
            setDisabledSave('');
            return false;
        }
        const formData = new FormData();
        formData.append('lead_id', props.updateData.id.substring(3));
        formData.append('call_date', date_time);
        formData.append('call_description', editorValues.body);
        formData.append('call_description_raw', editorValues.body);
        await Caller(props.editData ? lead_call_summary + "?id=" + props.editData.type_id : lead_call_summary, formData, props.editData ? PUT : POST, false, true).then((res) => {
            if (res.success === true) {
                props.refresh();
                props.close();
            }

        })
    }
    const allTrue = () => {
        if (date_time && editorValues.body) {
            return false
        } else {
            return true
        }
    }

    return (
        <Grid container >
            <Grid
                item
                xs={6}
                sx={{
                    paddingBottom: 1,
                }}
            >
                <TextField
                    id="datetime-local"
                    label="Date & Time"
                    type="datetime-local"
                    value={date_time}
                    onChange={handleDateChange}
                    sx={{ width: "80%" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            {showRecordingButton && <Grid
                item
                xs={6}
                sx={{
                    paddingBottom: 1,
                    display: "flex",
                    justifyContent: "right"
                }}
            >
                {/* <Select
                required
                displayEmpty
                style={{ width: "80%", height: 45 }}
                name="assigned_to"
                variant="outlined"
                defaultValue="Open"
                value={lead_sub_status}
                onChange={(e) => {
                    setLeadSubStatus(e.target.value);
                    (e.target.value === "Open" ? props.setProgressSteps(0, "Open") : (e.target.value === "Callback" || e.target.value === "Not Reachable" ? props.setProgressSteps(1, e.target.value) : props.setProgressSteps(2, e.target.value)))
                }}
                MenuProps={MenuProps}
            >
                    {leadSubstagesForCall?.map((value, key) => {
                        return <MenuItem key={key} value={value} >{value}</MenuItem>;
                    })}
                </Select> */}

                {(rightsArray.includes('102')) &&
                    <MDButton startIcon={<CloudDownloadIcon />} color='primary' className="action-button" size="small" onClick={() => callRecording()}>Download Recording</MDButton>
                }
            </Grid>}
            <Grid
                item
                xs={12}
            >
                <Editor
                    id="validationCustom03"
                    apiKey={TINYMCE_KEY}
                    required
                    name="body"
                    value={editorValues.body}
                    // onFocus={true}
                    // disabled={props.editData.call_description_raw ? true : false}
                    init={{
                        height: 350,
                        plugins: "image code",
                        directionality: "ltr",
                        menubar: false,
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | " +
                            "bullist numlist"
                    }}
                    onEditorChange={textValue}
                />
            </Grid>
            <Grid
                item
                xs={8}
                sx={{
                    paddingTop: 1,
                }}
            >
                <MDButton startIcon={<AddIcon />} color='info' className={`action-button ${disabledSave}`} size="small" onClick={submitConversation} disabled={allTrue()}>{props.editData ? "Update" : "Add"} Conversation</MDButton>
            </Grid>
        </Grid>


    )
}

export default CallSummary