import { Autocomplete, Box, Button, ClickAwayListener, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, TextField, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useEffect } from 'react';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { get_team_member_list } from 'Apis/config/Url';
import Caller from 'Apis/config/Caller';
import { GET, POST } from 'Apis/config/RequestType';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { lead_status } from 'Apis/Auth/auth';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { leads } from 'Apis/config/Url';
import { PUT } from 'Apis/config/RequestType';
import { get_lead_employees_name } from 'Apis/config/Url';
import e from 'cors';
import CustomStore from 'devextreme/data/custom_store';
import { get_product, get_sub_product } from 'Apis/config/Url';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSnackbar } from "notistack";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lead_stage } from 'Apis/config/Url';
import ProgressBar from './progress-bar';
import { send_payment_link } from "Apis/config/Url";
import { duplicate_leads } from "Apis/config/Url";
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
// moment.tz.setDefault("Asia/Kolkata");

import { useReducer } from "react";

import Cookies from "js-cookie";
import { RIGHTS } from "Apis/config/ResponseHandler";
import { EMP_USER_ID } from 'Apis/config/ResponseHandler';
import { LOGIN_USERTYPE_COOKIE } from '../../../../Apis/config/ResponseHandler';
import { lead_assignment_log } from 'Apis/config/Url';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "27vw",
        },
    },
};

const LeadInfo = (props) => {

    let lead_id = props.updateData.id;
    let previous_AssignTo = props.updateData.assigned_to
    let rights = Cookies.get(RIGHTS) ?? "";
    let rightsArray = rights.split(',') ?? [];
    let updateData = props.updateData ? props.updateData : "";
    const { enqueueSnackbar } = useSnackbar();
    const [values, setAllValues] = useState({
        lead_name: "",
        lead_status: "",
        lead_sub_status: "",

        assigned_to_id: "",
        assigned_to_value: "",

        lead_email: "",
        lead_phone: "",


        team_members: [],
        product_id: "",
        product_type: [],
        sub_product_id: null,
        enrollment_date: "",
        demo_days: "",
        investment: "",
        market_experience: "",
        broker: "",
        is_equity_portfolio: "",
        is_mutual_fund: "",
        is_cash: "",
        cash: "",
        city: "",
        category: ""
    })
    const [errors, setAllErrors] = useState({
        lead_name: "",
        lead_status: "",
        assigned_to: "",
        lead_email: "",
        lead_phone: "",
        product_id: "",
        product_type: "",
        sub_product_id: "",
        enrollment_date: "",
        demo_days: "",
        investment: "",
        market_experience: "",
        broker: "",
        is_equity_portfolio: "",
        is_mutual_fund: "",
        is_cash: "",
        cash: "",
        city: "",
        category: ""

    })

    const productTypeOption = [
        'NIFTY',
        'BANK NIFTY',
        'FINNIFTY',
        'STOCK OPTION',
    ];
    const [product, setProduct] = useState({
        product: [],
    });

    const [sub_product, setSubProduct] = useState({
        sub_product: [],
    });

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            Caller(get_lead_employees_name, "", GET, false, true).then(async (res) => {
                if (res.success === true) {
                    setAllValues((values) => ({
                        ...values,
                        team_members: res.data.data
                    }))
                }
            });
            Caller(get_product + "?isLoadingAll=true&sortColoumn=product_name&sortType=ASC&id=", '', GET, false, false).then((res) => {
                if (res.success === true) {
                    setProduct((product) => ({
                        ...product,
                        product: res.data.data
                    }))
                }
            })

            Caller(get_sub_product, '', GET, false, false).then((res) => {
                if (res.success === true) {
                    setSubProduct((sub_product) => ({
                        ...sub_product,
                        sub_product: res.data.filter(data => data.product_id == updateData.product_id)
                    }))
                }
            })

            if (updateData) {
                Caller(get_lead_employees_name, "", GET, false, true).then(async (res) => {
                    if (res.success === true) {
                        let arrayReporting = res.data.data.find(o => o.employee_user_id == updateData.assigned_to);
                        setAllValues((values) => ({
                            ...values,
                            team_members: res.data.data,
                            assigned_to_value: arrayReporting ? arrayReporting.employee_name : "",
                        }))
                    }
                });
                setAllValues((values) => ({
                    ...values,
                    lead_name: (updateData.first_name ?? "") + " " + (updateData.last_name ?? ""),
                    lead_status: updateData.lead_status,
                    lead_sub_status: updateData.lead_sub_status,
                    assigned_to_id: updateData.assigned_to === 0 ? "" : updateData.assigned_to,
                    lead_email: updateData.email ?? "",
                    lead_phone: updateData.mobile,
                    product_id: updateData.product_id,
                    sub_product_id: updateData.sub_product_id ?? null,
                    product_type: updateData.product_type ? updateData.product_type.split(",") : [],
                    enrollment_date: moment(updateData.enrollment_date).format('YYYY-MM-DD'),
                    demo_days: updateData.demo_days,
                    investment: updateData.investment == null || updateData.investment == 'null' ? 0 : updateData.investment,
                    market_experience: updateData.market_experience ?? "",
                    broker: updateData.broker ?? "",
                    is_equity_portfolio: updateData.is_equity_portfolio == 1 ? true : false,
                    is_mutual_fund: updateData.is_mutual_fund == 1 ? true : false,
                    is_cash: updateData.is_cash == 1 ? true : false,
                    cash: updateData.cash ? updateData.cash : "",
                    city: updateData.city && updateData.city != null ? updateData.city : "",
                    category: updateData.category

                }))
            }
        }
    }, [])

    const leadStatusChange = (e) => {
        setAllErrors((state) => ({
            ...state,
            lead_status: ""
        }))
        setAllValues((values) => ({
            ...values,
            lead_status: e.target.value
        }));
        switch (e.target.value) {
            case "New Lead":
                setAllValues((values) => ({
                    ...values,
                    lead_sub_status: "Open"
                }));
                break;
            case "Follow Up":
            case "Interested":
                setAllValues((values) => ({
                    ...values,
                    lead_sub_status: "Callback"
                }));
                break;
            default:
                setAllValues((values) => ({
                    ...values,
                    lead_sub_status: ""
                }));
                break;
        }
    }
    const emailUpdate = (e) => {
        setAllValues((state) => ({
            ...state,
            lead_email: e.target.value
        }))
        setAllErrors((state) => ({
            ...state,
            lead_email: ""
        }))
    }

    // const onMobileUpdate = (e) => {
    //     // const re = /^[1-9][0-9]*$/;
    //     const re = /^[6-9]\d{9}$/;
    //     console.log("mobile=>",e.target.value)
    //     if (re.test(e.target.value) || (e.target.value === "")) {
    //         setAllValues((state) => ({
    //             ...state,
    //             lead_phone: e.target.value
    //         }))
    //     }
    //     setAllErrors((state) => ({
    //         ...state,
    //         lead_phone: ""
    //     }))
    // }


    const onMobileUpdate = (e) => {
        const re = /^[6-9]\d{9}$/;
        const inputValue = e.target.value;

        // Update the state with the input value
        setAllValues((state) => ({
            ...state,
            lead_phone: inputValue
        }));

        // Validate the input against the regex pattern
        if (!re.test(inputValue) && inputValue !== "") {
            // If the input does not match the pattern and is not empty, set an error
            setAllErrors((state) => ({
                ...state,
                lead_phone: "Invalid mobile number"
            }));
        } else {
            // Clear any existing errors
            setAllErrors((state) => ({
                ...state,
                lead_phone: ""
            }));
        }
    };


    const onCityUpdate = (e) => {

        const re = /^[a-zA-Z ]+$/;

        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                city: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            city: ""
        }))
    }

    const capitalAmountUpdate = (e) => {
        const re = /^[0-9\b]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                investment: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            investment: ""
        }))
    }

    const demoDaysUpdate = (e) => {
        let len = e.target.value.length;
        // const re = /^[0-9\b]+$/; //old
        // const re = /^[0-9\b]+$/;
        const re = /^\d+$/;

        if (len >= 0 && len <= 2) {
            if (re.test(e.target.value) || (e.target.value === "")) {
                setAllValues((state) => ({
                    ...state,
                    demo_days: e.target.value
                }))
            }
            setAllErrors((state) => ({
                ...state,
                demo_days: ""
            }))
        }

    }

    const marketExperienceUpdate = (e) => {

        const re = /^[0-9\b]+$/;
        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((state) => ({
                ...state,
                market_experience: e.target.value
            }))
        }
        setAllErrors((state) => ({
            ...state,
            market_experience: ""
        }))
    }

    const cashAmountUpdate = (e) => {
        const re = /^[0-9\b]+$/;

        if (re.test(e.target.value) || (e.target.value === "")) {
            setAllValues((values) => ({
                ...values,
                cash: e.target.value
            }))
        }
        setAllErrors((values) => ({
            ...values,
            cash: ""
        }))
    }

    const selectAssignedTo = (e, newValue, clickType) => {
        setAllErrors((errors) => ({
            ...errors,
            assigned_to: ""
        }))
        if (clickType == 'clear') {
            setAllValues((values) => ({
                ...values,
                assigned_to_id: "",
                assigned_to_val: "",
            }));
        } else {
            let data = values.team_members?.find(o => o.employee_name == newValue);

            setAllValues((values) => ({
                ...values,
                assigned_to_value: newValue ? newValue : "",
                assigned_to_id: data ? data.employee_user_id : ''
            }));
            setAllErrors((errors) => ({
                ...errors,
                assigned_to_id: ""
            }))
        }

    }

    const brokerNameOption = [
        { key: "Zerodha", value: "Zerodha" },
        { key: "Upstox", value: "Upstox" },
        { key: "AngleOne", value: "AngleOne" },
        { key: "Grow", value: "Grow" },
        { key: "Dhan", value: "Dhan" },
        { key: "Paytm", value: "Paytm" },
        { key: "Kotak", value: "Kotak" },
        { key: "ICICI", value: "ICICI" },
        { key: "Fyers", value: "Fyers" },
        { key: "Espresso", value: "Espresso" },
    ];

    const equityPortfolioOption = [
        { key: "YES", value: "true" },
        { key: "NO", value: "false" },
    ];
    const mutualFundOption = [
        { key: "YES", value: true },
        { key: "NO", value: false },
    ];
    const handleOptionTypeChange = async (e) => {

        // Switch case for all dropdowns
        switch (e.target.name) {
            case "product_id": {
                setAllErrors((state) => ({
                    ...state,
                    sub_product_id: ""
                }));
                setAllValues((values) => ({ ...values, product_id: e.target.value }));
                Caller(get_sub_product, '', GET, false, false).then((res) => {
                    if (res.success === true) {
                        setSubProduct((sub_product) => ({
                            ...sub_product,
                            sub_product: res.data.filter(data => data.product_id == e.target.value)
                        }))
                    }
                })
                break;
            }

            case "product_type": {
                setAllErrors((state) => ({
                    ...state,
                    sub_product_id: ""
                }));
                setAllValues((values) => ({ ...values, product_type: e.target.value }));
                break;
            }
            case "sub_product_id": {
                setAllErrors((state) => ({
                    ...state,
                    sub_product_id: ""
                }));
                setAllValues((values) => ({ ...values, sub_product_id: e.target.value }));
                break;
            }
            case "broker": {
                setAllErrors((state) => ({
                    ...state,
                    sub_product_id: ""
                }));
                setAllValues((values) => ({ ...values, broker: e.target.value }));
                break;
            }
            case "is_equity_portfolio": {
                setAllErrors((state) => ({
                    ...state,
                    sub_product_id: ""
                }));
                setAllValues((values) => ({ ...values, is_equity_portfolio: e.target.value }));
                break;
            }
            case "is_mutual_fund": {
                setAllErrors((state) => ({
                    ...state,
                    sub_product_id: ""
                }));
                setAllValues((values) => ({ ...values, is_mutual_fund: e.target.value }));
                break;
            }
            case "category": {
                setAllErrors((state) => ({
                    ...state,
                    category: ""
                }));
                setAllValues((values) => ({ ...values, category: e.target.value }));
                break;
            }
        }

    };

    const updateLeadData = async (event) => {
 
        const formData = new FormData();
        formData.append("lead_guid", updateData.lead_guid);
        formData.append("first_name", updateData.first_name);
        formData.append("last_name", updateData.last_name);
        formData.append("product_id", values.product_id);
        formData.append("assigned_to", values.assigned_to_id == '' ? 0 : values.assigned_to_id);
        formData.append("email", values.lead_email);
        // formData.append("lead_status", values.lead_status);
        // formData.append("lead_sub_status", values.lead_sub_status);
        formData.append("mobile", values.lead_phone);
        formData.append("product_type", values.product_type);
        // formData.append("sub_product_id", values.sub_product_id);
        formData.append("sub_product_id", values.sub_product_id ? values.sub_product_id : null);
        formData.append("demo_days", values.demo_days == undefined ? 0 : values.demo_days);
        formData.append("investment", values.investment == '' ? null : values.investment);
        formData.append("market_experience", values.market_experience ? values.market_experience : null);
        formData.append("broker", values.broker);
        formData.append("is_equity_portfolio", values.is_equity_portfolio);
        formData.append("is_mutual_fund", values.is_mutual_fund);

        formData.append("is_cash", values.is_cash);
        formData.append("cash", values.cash ? values.cash : null);
        formData.append("city", values.city ? values.city : "");

        formData.append("category", values.category ? values.category : "");

        if (values.is_cash == true && values.cash != '') {
            formData.append('lead_status', "Closed");
            // formData.append('lead_sub_status', sub ? sub : "");
            formData.append('lead_sub_status', "");

            Caller(lead_stage + '?lead_guid=' + updateData.lead_guid, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    props.refresh();
                }
            });

        } else {
            formData.append("lead_status", values.lead_status);
            formData.append("lead_sub_status", values.lead_sub_status);
        }

        await Caller(leads + '?lead_guid=' + updateData.lead_guid, formData, PUT, false, true).then(async (res) => {
            if (res.success === true) {

                if (previous_AssignTo != values.assigned_to_id) {
                    const formData = new FormData();
                    formData.append('lead_id',lead_id.substring(3))
                    formData.append('previous_assign_to',previous_AssignTo)
                    formData.append('changed_assign_to',values.assigned_to_id)
                    await Caller(lead_assignment_log, formData, POST, false, true).then((res) => {
                        if (res.success === true) {
                            props.refresh();
                        }
                    });
                }
                props.refresh();
                enqueueSnackbar("Lead Info Updated Successfully.", {
                    variant: "success",
                    autoHideDuration: 1000,
                    TransitionProps: { direction: "left" },
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                });
                props.refresh();
                props.close();
            } else {
                if (res.error.mobile == 'mobile length must be less than or equal to 13 characters long') {
                    enqueueSnackbar(<pre>
                        {
                            `Invalid Mobile Number! 
Accepted Format:
1. Numeric digits only
2. Length - Min: 10, Max: 10`
                        }
                    </pre>, {
                        variant: "error",
                        autoHideDuration: 2000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });

                } else if (res.error.email == 'email must be a valid email') {
                    enqueueSnackbar("Invalid Email ID", {
                        variant: "error",
                        autoHideDuration: 1000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });
                } else if (res.error.product_id == 'product_id must be a number') {
                    enqueueSnackbar("Please Select a Product.", {
                        variant: "error",
                        autoHideDuration: 1000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });
                }
                else if (res.error.mobile) {
                    enqueueSnackbar("Phone Number should be vaild.", {
                        variant: "error",
                        autoHideDuration: 1000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });
                } else {
                    enqueueSnackbar("Data Updation Failed.", {
                        variant: "error",
                        autoHideDuration: 1000,
                        TransitionProps: { direction: "left" },
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                    });
                }

                for (const [key, value] of Object.entries(res.error)) {
                    setAllErrors((values) => ({
                        ...values,
                        [`${key}`]: value,
                    }))
                }
            }
        })



        if (values.is_cash == true && values.cash != '') {

            // await Caller(send_payment_link + "?lead_id=aks123", formData, POST, false, true)
            await Caller(send_payment_link + "?lead_id=" + props.updateData.id.substring(3), formData, POST, false, true)
                .then((res) => {
                    if (res.success === true) {
                        props.refresh();
                        // props.close();
                    } else if (res.success === false) {
                        // setDisabledSave("");
                        for (const [key, value] of Object.entries(res.message["errors"])) {
                            setAllErrors((values) => ({
                                ...values,
                                [`${key}`]: value,
                            }));
                        }
                    }
                })
                .catch((error) => {
                    alert(JSON.stringify(error.message));
                });




            // Caller(lead_stage + '?lead_guid=' + updateData.lead_guid, formData, POST, false, true).then((res) => {
            //     if (res.success === true) {
            //         props.refresh();

            //     }
            // });

        }

    }

    const duplicateLeadData = async (event) => {
        const formData = new FormData();
        // formData.append("lead_guid", updateData.lead_guid);
        formData.append("first_name", updateData.first_name);
        formData.append("last_name", updateData.last_name);
        formData.append("product_id", values.product_id);
        formData.append("sub_product_id", values.sub_product_id ? values.sub_product_id : null);
        // formData.append("lead_status", "New Lead");
        // formData.append("lead_sub_status", "Open");
        formData.append("mobile", values.lead_phone);
        formData.append("email", values.lead_email);
        formData.append("investment", values.investment);
        formData.append("assigned_to", values.assigned_to_id == '' ? 0 : values.assigned_to_id);

        // formData.append("product_type", values.product_type);
        // formData.append("demo_days", values.demo_days == undefined ? 0 : values.demo_days);
        // formData.append("market_experience", values.market_experience);
        // formData.append("broker", values.broker);
        // formData.append("is_equity_portfolio", values.is_equity_portfolio);
        // formData.append("is_mutual_fund", values.is_mutual_fund);
        // formData.append("is_cash", values.is_cash);
        formData.append("city", values.city);
        formData.append("category", values.category);

        if (values.is_cash == true && values.cash != '') {
            formData.append('lead_status', "Closed");
            // formData.append('lead_sub_status', sub ? sub : "");
            formData.append('lead_sub_status', "");

            Caller(lead_stage + '?lead_guid=' + updateData.lead_guid, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    props.refresh();
                }
            });

        } else {
            formData.append("lead_status", "New Lead");
            formData.append("lead_sub_status", "Open");
        }

        Caller(duplicate_leads, formData, POST, false, true).then((res) => {
            if (res.success === true) {
                enqueueSnackbar("Lead Duplicate Successfully.", {
                    variant: "success",
                    autoHideDuration: 1000,
                    TransitionProps: { direction: "left" },
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                });

                props.refresh();

            } else {
                enqueueSnackbar("Lead Already Exist! ", {
                    variant: "error",
                    autoHideDuration: 2000,
                    TransitionProps: { direction: "left" },
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                });
                for (const [key, value] of Object.entries(res.error)) {
                    setAllErrors((values) => ({
                        ...values,
                        [`${key}`]: value,
                    }))
                }
            }
        })
    }



    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    // const handleClose = () => window.location.reload(true)
    const handleClose = () => {
        //     // setShow(false)
        //     // window.location.refresh();
        //     // reload();

        //     // props.refresh();
        //     // setTimeout(window.location.href = "./overview-lead.js",1000);
        //     // setTimeout(window.location.href = "progress-bar.js",1000);

        // let page = window.open('overview-lead.js')
        // page.location.reload(true);


        // this.forceUpdate();

        forceUpdate();

    };

    const allTrue = () => {
        const re = /^[0-9]+$/;
        const cityRe = /^[a-zA-Z ]+$/;

        if (!re.test(values.lead_phone) || (values.lead_phone.length < 10 || values.lead_phone.length > 10) || values.product_id == "" || values.product_id == null || (values.city && !cityRe.test(values.city))) {
            return true;
        } else {
            return false;
        }
    }

    return (

        <Box
            //   component="form"
            //   onSubmit={addLookup}
            sx={{

                "#demo-row-radio-buttons-group-label": { fontSize: "medium", textAlign: "left" },
                ".role-select-custom": { width: "100%" },
                ".add-label": { pb: 1 },
                ".radio-button-custom": { fontSize: "small" },
                ".css-1h9h5al-MuiAutocomplete-root .MuiOutlinedInput-root": { padding: "5px" },
            }}
            noValidate
        >
            <Grid container /*spacing={3}*/
                spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {(rightsArray.includes('111')) &&
                    <Grid item xs={2} sm={3} md={3}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <InputLabel className='add-label'>Assigned To</InputLabel>
                        <Autocomplete
                            name="assigned_to"
                            value={values.assigned_to_value}
                            style={{ width: "100%" }}
                            onChange={selectAssignedTo}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                            error={!!errors.assigned_to}
                            options={values.team_members?.map((value) => {
                                return value.employee_name ?? "";
                            })}
                            sx={{
                                ".MuiOutlinedInput-root": { padding: "0px", height: 45 }
                            }}
                        />

                        <FormHelperText error>{errors.assigned_to === "" ? '' : errors.assigned_to}</FormHelperText>
                    </Grid>}


                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>Email</InputLabel>
                    <TextField
                        variant="outlined"
                        style={{ width: "100%" }}
                        name='lead_email'
                        value={values.lead_email}
                        disabled={(Cookies.get(LOGIN_USERTYPE_COOKIE)) == 1 ? false : true}
                        onChange={emailUpdate}
                        error={!!errors.lead_email}
                        helperText={errors.lead_email === "" ? '' : errors.lead_email}
                        inputProps={{
                            maxLength: 100
                        }}
                    />
                </Grid>
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>Phone <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <TextField
                        variant="outlined"
                        style={{ width: "100%" }}
                        name='lead_phone'
                        disabled={(Cookies.get(LOGIN_USERTYPE_COOKIE)) == 1 ? false : true}
                        value={values.lead_phone}
                        onChange={onMobileUpdate}
                        error={!!errors.lead_phone}
                        helperText={errors.lead_phone === "" ? '' : errors.lead_phone}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start" sx={{
                        //         '.MuiTypography-root': { font: 'inherit' }
                        //     }}>+91</InputAdornment>
                        // }}
                        inputProps={{
                            maxLength: 10
                        }}
                    />
                </Grid>

                {/* Product */}
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {/* <InputLabel className='add-label'>Product</InputLabel> */}
                    <InputLabel className='add-label'>Product <sup style={{ color: "red" }}>*</sup></InputLabel>
                    <Select
                        required
                        displayEmpty
                        style={{ width: "100%", height: 45 }}
                        name="product_id"
                        variant="outlined"
                        defaultValue=""
                        disabled={(Cookies.get(LOGIN_USERTYPE_COOKIE)) == 1 ? false : true}
                        value={values.product_id}
                        error={!!errors.product_id}
                        onChange={handleOptionTypeChange}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="">--Select--</MenuItem>
                        {product.product?.map((e, key) => {
                            return <MenuItem key={key} value={e.product_id} sx={{ color: "white", backgroundColor: "black" }}>{e.product_name}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText error>{errors.product_id === "" ? '' : errors.product_id}</FormHelperText>
                </Grid>

                {/* sub product */}
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>Sub Product</InputLabel>
                    <Select
                        required
                        displayEmpty
                        style={{ width: "100%", height: 45 }}
                        name="sub_product_id"
                        variant="outlined"
                        disabled={(Cookies.get(LOGIN_USERTYPE_COOKIE)) == 1 ? false : true}
                        defaultValue={updateData.sub_product_id}
                        value={values.sub_product_id}
                        error={!!errors.sub_product_id}
                        onChange={handleOptionTypeChange}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={null}>--Select--</MenuItem>
                        {sub_product.sub_product?.map((e, key) => {
                            return <MenuItem key={key} value={e.sub_product_id} sx={{ color: "white", backgroundColor: "black" }}>{e.product_name}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText error>{errors.sub_product_id === "" ? '' : errors.sub_product_id}</FormHelperText>
                </Grid>



                {/* Product type */}
                {(values.product_id === 2) &&
                    <Grid item xs={2} sm={3} md={3}
                        // display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <InputLabel className='add-label'>Product Type</InputLabel>

                        <Select
                            displayEmpty
                            style={{ width: "100%", height: 45 }}
                            name="product_type"
                            variant="outlined"
                            multiple
                            // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                            defaultValue={values.product_type}
                            renderValue={(selected) => selected.join(', ')}
                            value={values.product_type}
                            error={!!errors.product_type}
                            helperText={errors.product_type === "" ? '' : errors.product_type}
                            placeholder="--Select--"
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setAllValues((values) => ({
                                    ...values,
                                    product_type: typeof value === 'string' ? value.split(',') : value
                                }))
                                setAllErrors((state) => ({
                                    ...state,
                                    [`${e.target.name}`]: ""
                                }))
                            }}
                            MenuProps={MenuProps}
                        >
                            {productTypeOption?.map((value, key) => {
                                return <MenuItem key={key} value={value}>
                                    <Checkbox checked={values.product_type.indexOf(value) > -1} />
                                    <ListItemText primary={value} sx={{ '.MuiTypography-root': { fontSize: "0.85rem" } }} />
                                </MenuItem>;
                            })}
                        </Select>
                    </Grid>
                }

                {/* Demo Days */}
                {(values.product_id === 2) &&
                    <Grid item xs={2} sm={3} md={3}
                        // display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <InputLabel className='add-label'>Demo Days</InputLabel>
                        <TextField
                            variant="outlined"
                            placeholder="Enter the Number of Days"
                            // type="number"
                            // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                            style={{ width: "100%" }}
                            name='demo_days'
                            value={values.demo_days}
                            onWheel={(e) => e.target.blur()}
                            onChange={demoDaysUpdate}
                            error={!!errors.demo_days}
                            helperText={errors.demo_days === "" ? '' : errors.demo_days}
                            inputProps={{
                                maxLength: 2
                            }}
                        />
                    </Grid>
                }

                {/* capital amount */}
                {(values.product_id === 2) &&
                    <Grid item xs={2} sm={3} md={3}
                        // display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <InputLabel className='add-label'>Capital Amount</InputLabel>
                        <TextField
                            variant="outlined"
                            style={{ width: "100%" }}
                            name='investment'
                            // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                            value={values.investment}
                            onChange={capitalAmountUpdate}
                            error={!!errors.investment}
                            helperText={errors.investment === "" ? '' : errors.investment}
                            inputProps={{
                                maxLength: 9
                            }}
                        />
                    </Grid>
                }

                {/* Experience In The Market */}
                {/* {(values.product_id === 2) && */}
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {/* <InputLabel className='add-label'>Experience In The {<br />} Market</InputLabel> */}
                    <InputLabel className='add-label'>Experience In The Market</InputLabel>
                    <TextField
                        variant="outlined"
                        placeholder="Number of months"
                        style={{ width: "100%" }}
                        name='market_experience'
                        // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                        value={values.market_experience}
                        onChange={marketExperienceUpdate}
                        error={!!errors.market_experience}
                        helperText={errors.market_experience === "" ? '' : errors.market_experience}
                        inputProps={{
                            maxLength: 2
                        }}
                    />
                </Grid>
                {/* } */}

                {/* Broker Name */}
                {/* {(values.product_id === 2) && */}
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>Broker Name</InputLabel>
                    <Select
                        required
                        displayEmpty
                        style={{ width: "100%", height: 45 }}
                        name="broker"
                        variant="outlined"
                        // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                        defaultValue=""
                        value={values.broker}
                        error={!!errors.broker}
                        onChange={handleOptionTypeChange}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="">--Select--</MenuItem>
                        {brokerNameOption?.map((e, key) => {
                            return <MenuItem key={key} value={e.value} sx={{ color: "white", backgroundColor: "black" }}>{e.key}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText error>{errors.broker === "" ? '' : errors.broker}</FormHelperText>
                </Grid>
                {/* } */}

                {/* Equity Portfolio */}
                {/* {(values.product_id === 2) && */}
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>Equity Portfolio</InputLabel>
                    <Select
                        required
                        displayEmpty
                        style={{ width: "100%", height: 45 }}
                        name="is_equity_portfolio"
                        variant="outlined"
                        // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                        defaultValue=""
                        value={values.is_equity_portfolio}
                        error={!!errors.is_equity_portfolio}
                        onChange={handleOptionTypeChange}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="">--Select--</MenuItem>
                        {equityPortfolioOption?.map((e, key) => {
                            return <MenuItem key={key} value={e.value} sx={{ color: "white", backgroundColor: "black" }}>{e.key}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText error>{errors.is_equity_portfolio === "" ? '' : errors.is_equity_portfolio}</FormHelperText>
                </Grid>
                {/* } */}

                {/* Mutual Fund */}
                {(values.product_id === 2) &&
                    <Grid item xs={2} sm={3} md={3}
                        // display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <InputLabel className='add-label'>Mutual Fund</InputLabel>
                        <Select
                            required
                            displayEmpty
                            style={{ width: "100%", height: 45 }}
                            name="is_mutual_fund"
                            variant="outlined"
                            defaultValue=""
                            // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                            value={values.is_mutual_fund}
                            error={!!errors.is_mutual_fund}
                            onChange={handleOptionTypeChange}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value="">--Select--</MenuItem>
                            {mutualFundOption?.map((e, key) => {
                                return <MenuItem key={key} value={e.value} sx={{ color: "white", backgroundColor: "black" }}>{e.key}</MenuItem>;
                            })}
                        </Select>
                        <FormHelperText error>{errors.is_mutual_fund === "" ? '' : errors.is_mutual_fund}</FormHelperText>
                    </Grid>
                }

                {/* is_cash checkbox */}
                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <FormGroup>
                        <FormControlLabel
                            // style={{ marginTop: 20 }}
                            control={
                                <Checkbox
                                    name="is_cash"
                                    value={values.is_cash}
                                    // defaultChecked={true}
                                    defaultChecked={props.updateData.is_cash == 1 ? true : false}
                                    error={!!errors.is_cash}
                                    onChange={e => {
                                        if (e.target.checked == false) {
                                            setAllValues((values) => ({
                                                ...values,
                                                cash: ""
                                            }))
                                        }
                                        setAllValues((values) => ({
                                            ...values,
                                            is_cash: e.target.checked
                                        }))
                                    }}
                                />
                            }
                            label="Cash" />
                        <FormHelperText error>{errors.is_cash === "" ? '' : errors.is_cash}</FormHelperText>
                    </FormGroup>
                </Grid>

                {values.is_cash == true &&
                    <Grid item xs={2} sm={3} md={3}
                        // display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <InputLabel className='add-label'>Amount</InputLabel>

                        <TextField
                            variant="outlined"
                            // placeholder="Number of months"
                            style={{ width: "100%" }}
                            name='cash'
                            // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                            value={values.cash}
                            // onChange={marketExperienceUpdate}
                            onChange={cashAmountUpdate}
                            error={!!errors.cash}
                            helperText={errors.cash === "" ? '' : errors.cash}
                            inputProps={{
                                maxLength: 9
                            }}
                        />
                        <FormHelperText error>{errors.cash === "" ? '' : errors.cash}</FormHelperText>
                    </Grid>}


                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>City</InputLabel>
                    <TextField
                        variant="outlined"
                        style={{ width: "100%" }}
                        name='city'
                        // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                        value={values.city != null ? values.city : ""}
                        // onChange={onMobileUpdate}
                        onChange={onCityUpdate}
                        error={!!errors.city}
                        helperText={errors.city === "" ? '' : errors.city}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Grid>


                <Grid item xs={2} sm={3} md={3}
                    // display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <InputLabel className='add-label'>Category<sup style={{ color: "red" }}>*</sup></InputLabel>
                    <Select
                        displayEmpty
                        style={{ width: "100%", height: 45 }}
                        name="category"
                        variant="outlined"
                        // disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}
                        defaultValue=""
                        value={values.category}
                        error={!!errors.category}
                        onChange={handleOptionTypeChange}
                    // MenuProps={MenuProps}
                    >
                        {/* <MenuItem value=""></MenuItem>
                        {brokerNameOption?.map((e, key) => {
                            return <MenuItem key={key} value={e.value} sx={{ color: "white", backgroundColor: "black" }}>{e.key}</MenuItem>;
                        })} */}
                        <MenuItem value="L1">L1</MenuItem>
                        <MenuItem value="L2">L2</MenuItem>
                        <MenuItem value="L3">L3</MenuItem>


                    </Select>
                    <FormHelperText error>{errors.category === "" ? '' : errors.category}</FormHelperText>
                </Grid>



            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: 25, float: "right" }}>
                {/* <MDButton startIcon={<TransferWithinAStationIcon />} color='primary' className="action-button" size="small" onClick={updateLeadData} disabled={(Cookies.get(EMP_USER_ID) == 1 || Cookies.get(EMP_USER_ID) == 2 || Cookies.get(EMP_USER_ID) == 107) ? false : true}>Update</MDButton> */}
                <MDButton startIcon={<TransferWithinAStationIcon />} style={{ marginRight: 25 }} color='primary' className="action-button" size="small" onClick={() => { updateLeadData(); handleClose(); }} disabled={allTrue()}>Update</MDButton>
                <MDButton startIcon={<AddIcon sx={{ fontSize: 40 }} />} color='success' className="action-button" size="small" onClick={() => { duplicateLeadData(); handleClose(); }}>Duplicate Lead</MDButton>
            </Grid>
        </Box >
    );
};

export default LeadInfo;