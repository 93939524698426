/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LEAD_ASSIGN_MODE, UNIVERSAL_SEARCH_COOKIE } from 'Apis/config/ResponseHandler';
import { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { RIGHTS } from 'Apis/config/ResponseHandler';
import { NOT_VERIFIED_OR_SUBMITTED } from 'Apis/config/ResponseHandler';
import { EMP_USER_ID } from 'Apis/config/ResponseHandler';
import Caller from "Apis/config/Caller";
import { logout } from 'Apis/config/Url';

import { check_in } from 'Apis/config/Url';
import { check_out } from 'Apis/config/Url';
import { check_login_status } from 'Apis/config/Url';

import { GET, POST, PUT } from "Apis/config/RequestType";
import { Autocomplete, Box, Popover, TextField } from '@mui/material';
// import { render } from '@testing-library/react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { universal_search } from 'Apis/config/Url';
import { getWhatsappNotification } from 'Apis/config/Url';

import data from 'layouts/tables/data/authorsTableData';
import Badge from '@mui/material/Badge';
// import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import ToggleButton from '@mui/material/ToggleButton';
// import moment from 'moment';
import { Logout as LogoutIcon, Login as LoginIcon, Timer, Padding } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import NotificationItem from "examples/Items/NotificationItem";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LOGIN_USERTYPE_COOKIE } from 'Apis/config/ResponseHandler';

// import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { CHATNOTIFICATION } from 'Apis/config/ResponseHandler';
import { ScrollView } from 'devextreme-react';



function DashboardNavbar(props, { absolute, light, isMini }) {
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [openNotificationPopper, setOpenNotificationPopper] = useState(false);
  const popoverRef = useRef(null);
  const [isProfile, setIsProfile] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0);
  const [initialRender, setInitialRender] = useState(true)

  // For timer
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);

  const [timeDifference, setTimeDifference] = useState(0)



  if (initialRender) {
    if (props.isUpdatedClicked) {
      setIsProfile(true)
    }
    setInitialRender(false)
  }
  // if(props.isUpdatedClicked){
  //   setIsProfile(true)
  // }
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNMenu, setOpenNMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  // const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => {
    Cookies.remove(LOGIN_AUTH_COOKIE);
    Cookies.remove(LOGIN_REFRESH_COOKIE);
    Cookies.remove(RIGHTS);
    Cookies.remove(NOT_VERIFIED_OR_SUBMITTED);
    Cookies.remove(EMP_USER_ID);
    Cookies.remove(LEAD_ASSIGN_MODE);
    Cookies.remove(LOGIN_USERTYPE_COOKIE);
    // setOpenMenu(event.currentTarget);
  }
  const handleCloseMenu = () => setOpenMenu(false);

  const userLogout = async () => {
    await Caller(logout, '', POST, false, false);
    handleOpenMenu();
  }

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (event.currentTarget.performance.navigation.type == 2) {
        await Caller(logout, '', POST, false, false);
        handleOpenMenu();
      }

    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // const handleNotificationOpenMenu = (event) => setOpenNMenu(event.currentTarget);
  const handleNotificationOpenMenu = (event) => {
    // setNotificationCount(0); // Clear notifications when the menu is opened
    setOpenNMenu(event.currentTarget);
    setOpenNotificationPopper(true);
  };
  const handleNotificationCloseMenu = () => setOpenNMenu(false);
  // Render the notifications menu
  const renderNotificationMenu = () => (
    <Menu
      anchorEl={openNMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "Top",
        horizontal: "right",
      }}
      open={Boolean(openNMenu)}
      onClose={handleNotificationCloseMenu}
      sx={{ mt: 4 }}
    >
      <NotificationItem icon={<Badge badgeContent={notifications.notificationCount} color="primary"><MailIcon color="action" /></Badge>} title="Anil" />

    </Menu>

  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });


  const handleSearchTextChange = async (e) => {
    let s = e.target.value;
    setSearchText(s);

    if (s === '') {
      setAnchorEl(null);
      setOpenPopper(false);
    } else {
      setAnchorEl(e.currentTarget);
      setOpenPopper(true);
    }

    await Caller(universal_search + `?s=${s}`, '', GET, false, false).then((data) => {
      Cookies.set(UNIVERSAL_SEARCH_COOKIE, e.target.value)
      setSearchData(data.data)
      if (data.data.length == 0) {
        setOpenPopper(false);
      }
    })

  };

  const handleClosePopover = () => {
    setOpenPopper(false);
  };

  const handleCloseNotificationPopover = (data) => {
    setOpenNotificationPopper(false);
  };

  const handleTextFieldClick = (event) => {
    if (searchText !== '') {
      setAnchorEl(event.currentTarget);
    }
  };


  const liCss = {
    marginBottom: '5px',
    textDecoration: 'none',
    color: 'black',
    padding: 3,
    marginBottom: "3px ",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  }


  const timer = () => {
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  }

  const whatsAppNotification = async () => {
    await Caller(getWhatsappNotification, '', GET, false, false).then((res) => {
      if (res) {
        setNotificationCount(res.data.notificationCount)
        setNotifications(res.data.chatdata);
      }
    })
  }


  useEffect(() => {
    (async () => {
      await Caller(check_login_status, '', POST, false, false).then((res) => {
        if (res.data?.timeDifference) {
          setIsRunning(true);
          setTimeDifference(res.data?.timeDifference || 0);
          setTime(res.data.timeDifference || 0);
          timer();
        }
        else {
          setIsRunning(false);
          clearInterval(intervalRef.current);
          setTime(0);
          setTimeDifference(0);
        }
      })

      whatsAppNotification();
      const intervalId = setInterval(whatsAppNotification, 10000);

    })()
  }, []);


  const startStopwatch = async () => {
    setIsRunning(true);
    await Caller(check_in, '', POST, false, false);
    timer();
  };

  const stopStopwatch = async () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
    setTime(0);
    await Caller(check_out, '', POST, false, false);
    setTimeDifference(0);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        padding: '0px',
      })}

    // sx={{padding:0}}
    >
      <DashboardLayout>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => ({
              ...navbarRow(theme, { isMini }),
              padding: '0px',
            })}
          >
            {/* Pass the modified title to the Breadcrumbs component */}
            <Breadcrumbs icon="home" title={route[route.length - 1] === 'all_payment' ? 'All Payment' : route[route.length - 1].startsWith("update") ? "Update" : route[route.length - 1] == "emailtemplates" ? "Email Templates" : route[route.length - 1]} route={route} light={light} />

          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => ({
              ...navbarRow(theme, { isMini }),
              padding: '0px',
            })}>

              {!isRunning && <Button variant="contained" endIcon={<LoginIcon style={{ color: 'white' }} />} onClick={startStopwatch} style={{ color: 'white', marginRight: '10px', backgroundColor: "#4DA851", width: '150px' }}>Check-in </Button>}
              {isRunning && <Button variant="contained" endIcon={<LogoutIcon style={{ color: 'white' }} />} onClick={stopStopwatch} style={{ color: 'white', marginRight: '10px', backgroundColor: "#F44335", width: '170px' }}> Check-out </Button>}

              {isRunning && <Typography variant="h4" component="h4" sx={{ marginRight: '10px' }}>
                {formatTime(time)}
              </Typography>}

              <TextField
                required
                id="outlined-required"
                style={{ width: "60%" }}
                placeholder="Search..."
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                onChange={handleSearchTextChange}
                onClick={handleTextFieldClick}
                inputRef={(input) => openPopper && input?.focus()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="medium" style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Popover
                open={openPopper}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}

                placement={'bottom-start'}
                PaperProps={{
                  style: {
                    width: '250px',
                  },

                }}
                ref={popoverRef}
              >
                <Box sx={{ p: 1, borderRadius: 1, bgcolor: '#F9F7F7' }}>
                  <ul style={{ listStyleType: 'none' }}>
                    {searchData.map(data => (

                      <Link to={(data.link == "/products" ? "/product-assignment" : data.link) + "?isUniversalSearch=true" + (data.link == "/products" ? "&productsTab=true" : (data.link == "/product-assignment" ? "&productsAssignmentTab=true" : ""))}><li style={liCss} onMouseEnter={(e) => e.target.style.backgroundColor = '#E5E3E3'} onMouseLeave={(e) => e.target.style.backgroundColor = '#F9F7F7'}> <span>{data.name}</span> <span style={{ float: 'right', marginRight: '10px', marginBottom: '3px' }}> <Badge badgeContent={data.count} color="primary" /></span> </li></Link>

                    ))}
                  </ul>
                </Box>
              </Popover>

              {!isProfile && <Link
                to="/profile"
              >
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleNotificationOpenMenu}
              >
                <Badge badgeContent={notificationCount} color="secondary">
                  <WhatsAppIcon sx={iconsStyle} />
                </Badge>
              </IconButton>

              <Popover
                open={openNotificationPopper}
                onClose={() => handleCloseNotificationPopover(null)}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 80, left: 1600 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                placement={'bottom-start'}
                PaperProps={{
                  style: {
                    width: '500px',
                  },
                }}
              >
                <Box sx={{ p: 1, borderRadius: 1, bgcolor: '#F9F7F7' }}>
                  <ScrollView height="100%">
                    <ul style={{ listStyleType: 'none', maxHeight: "40vh" }}>
                      {
                        notifications.map(data => (
                          <Link to="/chat" onClick={() => handleCloseNotificationPopover(data)}> <li style={liCss} onMouseEnter={(e) => e.target.style.backgroundColor = '#E5E3E3'} onMouseLeave={(e) => e.target.style.backgroundColor = '#F9F7F7'}> <span>{data.first_name}</span> <span>({data.mobile.substring(0, 5) + '....'}):</span> <span>{data.message_type ? (data.message_type == "document" ? "Document" : (data.message_type == "image" ? "Image" : (data.message_type == "video" ? "Video" : data.whatsapp_message))) : data.whatsapp_message}</span>   </li></Link>

                        ))
                      }
                    </ul>
                  </ScrollView>
                </Box>

              </Popover>


              <Link to="/authentication/sign-in">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  // onClick={handleOpenMenu}
                  onClick={userLogout}
                >
                  <Icon sx={iconsStyle} >logout</Icon>
                </IconButton>
              </Link>

            </MDBox>

          )}
        </Toolbar>
      </DashboardLayout>
    </AppBar >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
