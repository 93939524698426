/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { ExpandMore } from '@mui/icons-material';
import { Box, DialogActions, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material'
import Caller from 'Apis/config/Caller';
import { GET, POST } from 'Apis/config/RequestType';
import { RIGHTS } from 'Apis/config/ResponseHandler';
import { EMP_USER_ID } from 'Apis/config/ResponseHandler';
import { NOT_VERIFIED_OR_SUBMITTED } from 'Apis/config/ResponseHandler';
import { LOGIN_REFRESH_COOKIE } from 'Apis/config/ResponseHandler';
import { LOGIN_AUTH_COOKIE } from 'Apis/config/ResponseHandler';
import { update_lookup } from 'Apis/config/Url';
import { get_lookup_type, create_lookup } from 'Apis/config/Url';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

const VerifyMessagePopup = (props) => {
    const navigate = useNavigate();
    const handleLogout = (event) => {
        Cookies.remove(LOGIN_AUTH_COOKIE);
        Cookies.remove(LOGIN_REFRESH_COOKIE);
        Cookies.remove(RIGHTS);
        Cookies.remove(NOT_VERIFIED_OR_SUBMITTED);
        Cookies.remove(EMP_USER_ID);
        navigate("/authentication/sign-in")
        //   setOpenMenu(event.currentTarget);
    }

    return (
        <Box
            component="form"
            // onSubmit={submission_review_verify}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { width: "100%" },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' }
            }}
            noValidate
        >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12} sm={12} md={12}>

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <p className='add-label'>Your personal details are not yet approved, please verify and submit for approval. </p>

                </Grid>
                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "10%",
                        marginBottom: "0%",
                        width: "104%",
                        borderRadius: '0.5rem',
                        transform: "translateY(35%)",
                        '.action-button': { width: 100 }
                    }}
                >
                    <MDButton color='success' className={`action-button`} onClick={props.close}>Ok</MDButton>
                    <MDButton color='warning' className="action-button" onClick={handleLogout}>Cancel</MDButton>
                </DialogActions>
            </Grid>
        </Box>
    )
}

export default VerifyMessagePopup;