import React, { useState } from 'react'
import { Grid, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import MDButton from 'components/MDButton';
import AddIcon from '@mui/icons-material/Add';
import { lead_notes } from 'Apis/config/Url';
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { useEffect } from 'react';
import { PUT } from 'Apis/config/RequestType';
import { TINYMCE_KEY } from 'Apis/config/ResponseHandler';

const Note = (props) => {

    const [editorValues, setEditorValues] = useState({
        body: ""
    })
    const [disabledSave, setDisabledSave] = useState();

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.editData) {
                setEditorValues((values) => ({
                    ...values,
                    body: props.editData.description ?? ""
                }))
            }
        }
    }, [])


    const textValue = (content, editor) => {
        let re = /^.{0,1000}$/;
        if (content.match(re) || content === '') {
            setEditorValues((state) => ({
                ...state, body: content
            }));
        }
    }


    const submitNote = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        setDisabledSave('disabled');
        if (!event.target.checkValidity()) {
            setDisabledSave('');
            return false;
        }
        const formData = new FormData();
        formData.append('lead_id', props.updateData.id.substring(3));
        formData.append('lead_description', editorValues.body);
        console.table([...formData])
        await Caller(props.editData ? lead_notes + "?id=" + props.editData.type_id : lead_notes, formData, props.editData ? PUT : POST, false, true).then((res) => {
            if (res.success === true) {
                props.refresh();
                props.close();
            }
        })
    }
    const allTrue = () => {
        if (editorValues.body) {
            return false
        } else {
            return true
        }
    }


    return (
        <Grid container >
            <Grid
                item
                xs={12}
            >
                <Editor
                    id="validationCustom03"
                    apiKey={TINYMCE_KEY}
                    required
                    name="body"
                    value={editorValues.body}
                    init={{
                        height: 400,
                        plugins: "image code",
                        directionality: "ltr",
                        menubar: false,
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | " +
                            "bullist numlist"
                    }}
                    onEditorChange={textValue}
                />
            </Grid>
            <Grid
                item
                xs={5}
                sx={{
                    paddingTop: 1,
                }}
            >
                <MDButton startIcon={<AddIcon />} color='info' className={`action-button ${disabledSave}`} size="small" onClick={submitNote} disabled={allTrue()}>{props.editData ? "Update" : "Add"} Note</MDButton>
            </Grid>
        </Grid>


    )
}

export default Note;