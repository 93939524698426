/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";


// Data
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Icon, IconButton, Switch, TextField, useMediaQuery } from "@mui/material";
import { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Column, ColumnChooser, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import CloseIcon from '@mui/icons-material/Close';
import { UsersData } from "layouts/tables/data/mockdata";
import { GET } from "Apis/config/RequestType";
import { update_user } from "Apis/config/Url";
import { PUT } from "Apis/config/RequestType";
import DeletePopup from "layouts/master-data/delete-popup";
import { delete_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useNavigate } from "react-router-dom";
import { getEmployeeUserDetails } from "Apis/Auth/auth";
import CustomStore from "devextreme/data/custom_store";
import { change_user_status } from "Apis/config/Url";
import { POST } from "Apis/config/RequestType";
import { get_employee_user_details } from "Apis/config/Url";
import MDBadge from "components/MDBadge";
import moment from 'moment';
const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}

import { permissions } from "Apis/Auth/auth";
import { RIGHTS, UNIVERSAL_SEARCH_COOKIE } from "Apis/config/ResponseHandler";
import Cookies from "js-cookie";


const employeeUserUserDetails = new CustomStore({
  key: 'employee_user_id',
  load(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "isLoadingAll"
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {

        if (i === "skip") {
          let page = (loadOptions[i] + 10) / 10;
          if (page !== 1) {
            params += `page=${page}&`;
          }
        } else if (i === "sort") {
          let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
          let sortColoumn = loadOptions[i][0]["selector"];
          params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
        } else if (i === "filter") {
          let filterO = loadOptions[i][0]["filterValue"];
          params += `s=${filterO}&`;
        } else {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      }
    });
    params = params.slice(0, -1);
    return getEmployeeUserDetails(params)
      .then((data) => ({
        data: data.data.data,
        totalCount: data.data.count,
        next: data.data.next,
        previous: data.data.previous
      }))
  },

});

const Employees = (props) => {

  let universalSearchCookie = Cookies.get(UNIVERSAL_SEARCH_COOKIE);
  Cookies.set(UNIVERSAL_SEARCH_COOKIE, "")

  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Employee Data 1");
  const [updateData, setUpdate] = useState("");
  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (reason !== 'backdropClick') {
        setUpdate("");
        setOpen(false);
        setDeleteOpen(false);
      }
    }
  };

  function cellRender(data) {
    return <img src={team2} style={{ borderRadius: "50%", width: "2.5rem", height: "2.5rem" }} />;
  }

  const selectPopupVisible = async (e) => {
    const formData = new FormData();
    formData.append("id", e.row.data.id);
    await Caller(get_employee_user_details + "?take=10&requireTotalCount=true&s=&id=" + e.row.data.employee_user_id, "", GET, false, true).then(async (res) => {
      if (res.success === true) {
        setUpdate(res.data.data);
      }
    });
    setPopupTitle("Update Employee");
    setOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const deleteVisible = (e) => {
    setUpdate(e.row.data.employee_user_id);
    setDeleteOpen(true);
  }
  const updateEmployee = (e) => {
    return navigate("/employees/update-" + e.row.data.employee_guid);
  }

  const handleChanged = async (event, id) => {

    let status = "";
    if (event.target.checked === false) {
      status = 0
    }
    else {
      status = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("active", status);
    await Caller(change_user_status + "?user_type=employee", formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }
  const verificationStatus = (data) => {
    if (data.data.is_submission_verified == 1 && data.data.is_submitted_review == 0) {
      return (
        <div >
          <span className="current-value">{data.data.first_name}  <MDBadge badgeContent="To be Verify" variant="gradient" color="warning" size="xs" container /></span>

        </div>
      );
      // return data.data.first_name +<MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
    }
    else {
      return data.data.first_name
    }

  }
  const returnLoginStatus = (data) => {
    switch (data.value) {
      case 1:
        return <MDBadge badgeContent="Online" variant="gradient" color="success" size="xs" container />
      case 0:
        return <MDBadge badgeContent="Offline" variant="gradient" color="dark" size="xs" container />
      default:
        break;
    }
    // return <MDBadge badgeContent="Online" variant="gradient" color="success" container />
  }

  const renderGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_active == 'Active') {
      func = true
      label = "Active"
    }
    else {
      func = false
      label = "Inactive"
    }

    return <FormControlLabel
      control={<Switch
        checked={func}
        color="secondary"
        onChange={(event) => handleChanged(event, data.data.employee_user_id)}
        inputProps={{ 'aria-label': 'controlled', role: 'switch' }}
      />}
      label={label} />
  }

  let deleteText = "Do you want to delete this Employee?";


  const renderLoginStatus = (data) => {
    
    if (data.data.checkin_status == "CHECK-IN") {
      return <MDBadge badgeContent="CHECK-IN" variant="gradient" color="success" size="xs" container />
    } else {
      return <MDBadge badgeContent="CHECK-OUT" variant="gradient" color="error" size="xs" container />
    }

  }

  const renderLoginAt = (data) => {
    if (data.data.login_at == null || data.data.login_at == '') {
      return <div> {""} </div>
    } else {

      // return <div> {moment(data.data.login_at).format('MM/DD/YYYY hh:mm:ss a')} </div>

      return <div>{moment(data.data.login_at).add(5, 'hours').add(30, 'minutes').format('MM/DD/YYYY hh:mm:ss a')}</div>

    }
  }

  const renderLogoutAt = (data) => {
    if (data.data.logout_at == null || data.data.logout_at == '') {
      return <div> {""} </div>
    } else {
      return <div> {moment(data.data.logout_at).format('DD/MM/YYYY hh:mm:ss')} </div>
    }
  }

  const renderDateOfExit = (data) => {
    console.log("Date = ", data.value);
    console.log("Data type = ", typeof data.value);
    if (data.value == '00/00/0000' || data.value == null) {
      return ""
    } else {
      return data.value
      // return <div> {moment(data.value).format('DD/MM/YYYY')} </div>
    }
  }

  return (
    <DashboardLayout>
      {deleteOpen && <DeletePopup deleteId={updateData} open={deleteOpen} deleteText={deleteText} close={handleClose} refresh={refreshGrid} path={delete_user + "?user_type=employee"} />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} key="employees_grid_1">
          <Grid item xs={12} key="employees_grid_2">
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Employees Table
                </MDTypography>
              </MDBox>
              {/* <EmployeesData1 data={updateData} close={handleClose} />
              <EmployeesData2 data={updateData} close={handleClose} /> */}
              <MDBox p={3}>
                <DataGrid
                  id="gridContainer"
                  keyExpr="employee_user_id"
                  dataSource={employeeUserUserDetails}
                  ref={userGrid}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                >
                  <ColumnChooser
                    enabled={true}
                    mode="select"
                  />
                  <Pager
                    visible={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true} />
                  <Paging defaultPageSize={10} />
                  <SearchPanel
                    visible={true}
                    defaultText={universalSearchCookie}
                  />
                  <Export
                    enabled={true}
                    fileName="Employees"
                  />
                  <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={(rightsArray.includes('102')) && true}
                    useIcons={true}
                  />
                  <Column
                    caption="Employee Id"
                    dataField="employee_id"
                    alignment="center"
                    minWidth={120}
                  />

                  <Column
                    caption="First Name"
                    dataField="first_name"
                    alignment="center"
                    minWidth={120}
                    cellRender={verificationStatus}
                  />

                  <Column
                    caption="Last Name"
                    dataField="last_name"
                    alignment="center"
                    minWidth={120}
                  />
                  <Column
                    caption="Employee Type"
                    dataField="user_type_name"
                    alignment="center"
                    minWidth={150}
                  />
                  {/* <Column
                    caption="Login Status"
                    dataField="is_logged_in"
                    alignment="center"
                    cellRender={returnLoginStatus}
                    minWidth={150}
                  /> */}
                  <Column
                    caption="Official Email ID"
                    dataField="username"
                    alignment="center"
                    minWidth={150}
                  />
                  <Column
                    caption="Official Mobile Number"
                    dataField="official_mobile_number"
                    alignment="center"
                    format="(###)-###-####"
                    minWidth={150}
                  />
                  {/* <Column
                    caption="Mobile"
                    dataField="mobile"
                    alignment="center"
                    format="(###)-###-####"
                    minWidth={150}
                  /> */}

                  {(rightsArray.includes('102')) &&
                    <Column
                      caption="Disable Login"
                      alignment="center"
                      dataField="is_active"
                      cellRender={renderGridStatus}
                      minWidth={150}
                    />
                  }
                  <Column
                    caption="Department Name"
                    alignment="center"
                    dataField="department_name"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Designation Name"
                    alignment="center"
                    dataField="designation_name"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Employment Type"
                    alignment="center"
                    dataField="employment_type"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Employment Status"
                    alignment="center"
                    dataField="employment_status"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Date of joining"
                    alignment="center"
                    dataField="date_of_joining"
                    // format="MM/dd/yyyy"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Date of birth"
                    alignment="center"
                    dataField="date_of_birth"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Gender"
                    alignment="center"
                    dataField="gender"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Marital Status"
                    alignment="center"
                    dataField="marital_status"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="UAN ID"
                    alignment="center"
                    dataField="uan"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="PAN Number"
                    alignment="center"
                    dataField="pan"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Aadhar Number"
                    alignment="center"
                    dataField="aadhar"
                    minWidth={150}
                    visible={false}
                  />
                  <Column
                    caption="Date of Exit"
                    alignment="center"
                    dataField="date_of_exit"
                    // cellRender={renderDateOfExit}
                    minWidth={150}
                    visible={false}
                  />
                  {/* <Column
                    caption="Reset Password"
                    alignment="center"
                    dataField="reset_password"
                    minWidth={150}
                    visible={true}
                  /> */}
                  {(rightsArray.includes('102')) &&
                    <Column
                      caption="Status"
                      alignment="center"
                      // dataField="is_logged_in"
                      dataField="checkin_status"
                      cellRender={renderLoginStatus}
                      minWidth={150}
                      visible={true}
                    />
                  }
                  {(rightsArray.includes('102')) &&
                    <Column
                      caption="Login Time"
                      alignment="center"
                      dataField="login_at"
                      format="dd/MM/yyyy, hh:mm:ss a"
                      // cellRender={renderLoginAt}
                      minWidth={150}
                      visible={true}
                    />
                  }
                  {(rightsArray.includes('102')) &&
                    <Column
                      caption="Logout Time"
                      alignment="center"
                      dataField="logout_at"
                      format="dd/MM/yyyy, hh:mm:ss a"
                      // cellRender={renderLogoutAt}
                      minWidth={150}
                      visible={true}
                    />
                  }
                  {(rightsArray.includes('102')) && <Column type="buttons" dataField="Actions" minWidth={150}>
                    <Button disabled={!rightsArray.includes('104') ? true : false} icon="edit" hint="Edit" onClick={updateEmployee} />
                    <Button disabled={!rightsArray.includes('104') ? true : false} hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} />
                  </Column>}

                  <Toolbar>

                    <Item name="searchPanel" />
                    <Item name="exportButton" />
                    <Item name="columnChooserButton"
                      locateInMenu="auto" />
                    {(rightsArray.includes('102')) &&
                      <Item location="after">
                        <MDButton disabled={!rightsArray.includes('104') ? true : false} variant="gradient" color="success" onClick={() => { navigate("/employees/create"); setUpdate(""); setPopupTitle("Add Employees"); }}>
                          Add&nbsp;
                          <Icon>add</Icon>&nbsp;
                        </MDButton>
                      </Item>
                    }
                  </Toolbar>

                </DataGrid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Employees;
