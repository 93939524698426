/* eslint-disable no-unused-vars */

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import "devextreme/dist/css/dx.light.css";
import Chart, { ArgumentAxis, Series, Legend } from "devextreme-react/chart";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
// import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/apnaresearch logo 4.png";
import Cookies from "js-cookie";
import { LOGIN_AUTH_COOKIE } from "Apis/config/ResponseHandler";
import { LOGIN_REFRESH_COOKIE } from "Apis/config/ResponseHandler";

import { NavLink, useNavigate } from "react-router-dom";
import { RIGHTS } from "Apis/config/ResponseHandler";
import { EMP_USER_ID } from "Apis/config/ResponseHandler";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  // check cookie is available or not , if available redirect to dashboard
  const navigate = useNavigate();
  useEffect(() => {

    let auth = Cookies.get(LOGIN_AUTH_COOKIE);
    let refresh = Cookies.get(LOGIN_REFRESH_COOKIE);
    let rights = Cookies.get(RIGHTS);
    let user_id = Cookies.get(EMP_USER_ID);

    if (auth == undefined && refresh == undefined && rights == undefined && user_id == undefined) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const authKeyParam = urlSearchParams.get('authkey');
      if (authKeyParam) {
        navigate("/authentication/create-password");
      } else {
        navigate("/authentication/sign-in");
      }
    } else {

      if (pathname == "/") {
        navigate("/dashboard");

      } else {
        navigate(pathname);
      }
    }
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        //return getRoutes(route.collapse);
      }

      if (route.route) {
        let auth = Cookies.get(LOGIN_AUTH_COOKIE);
        let refresh = Cookies.get(LOGIN_REFRESH_COOKIE);
        if (route.type === "collapse") {
          if (auth && refresh) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
          }
          else {
            return <Route path="/" element={<Navigate to="/authentication/sign-in" />} key={route.key} />;
          }
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }

      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brandDark}
            brandName="APNA RESEARCH PLUS"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <DashboardNavbar />
        </>
      )}

      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}
