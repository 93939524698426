/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import { getAdminUserDetails } from "Apis/Auth/auth";
import { get_related_admin_user } from "Apis/config/Url";
import Caller from "Apis/config/Caller";
import { useEffect, useState } from "react";
import { GET } from "Apis/config/RequestType";
const Overview = (props) => {

  const [values, setAllValues] = useState({
    first_name: '',
    last_name: "",
    email: "",
    phone: "",
    gender: '',
    marital_status: '',
    extension: '',
    mobile: "",
    profile_pic: "",
    department_name: '',
    designation_name: '',
    demat_role: '',
    employment_type: '',
    employment_status: '',
    date_of_joining: '',
    date_of_birth: '',


  });

  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);

      const updateEmployee = (e) => {
        return navigate("/employees/update/" + 1);
      }

      Caller(get_related_admin_user, "", GET, false, true).then(async (res) => {
        if (res.success === true) {
          setAllValues((values) => ({
            ...values,
            first_name: res.data[0].first_name ? res.data[0].first_name : '',
            last_name: res.data[0].last_name ? res.data[0].last_name : '',
            email: res.data[0].username ? res.data[0].username : '',
            phone: res.data[0].phone ? res.data[0].phone : '',
            gender: res.data[0].gender ? res.data[0].gender : '',
            marital_status: res.data[0].marital_status ? res.data[0].marital_status : '',
            extension: res.data[0].extension ? res.data[0].extension : '',
            mobile: res.data[0].official_mobile_number ? res.data[0].official_mobile_number : '',
            profile_pic: res.data[0].profile_pic ? res.data[0].profile_pic : '',
            designation_name: res.data[0].designation_name,
            department_name: res.data[0].department_name,
            demat_role: res.data[0].user_type_name,
            employment_type: res.data[0].employment_type,
            employment_status: res.data[0].employment_status,
            date_of_joining: res.data[0].date_of_joining,
            date_of_birth: res.data[0].date_of_birth,
          }))
        }
      });
    }
  }, []
  );


  return (
    <DashboardLayout>
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title={`${(values.first_name || '')}` + " " + `${(values.last_name || '')} `}
                description=""
                info={{
                  fullName: "" + (values.first_name || '') + " " + (values.last_name || '') + "",
                  mobile: "" + (values.mobile || '') + "",
                  gender: "" + (values.gender || '') + "",
                  'marital Status': "" + (values.marital_status || '') + "",
                  phone: "" + (values.phone || '') + "",
                  email: "" + (values.email || '') + "",
                  department: "" + (values.department_name || '') + "",
                  'demat Role': "" + (values.demat_role?.charAt(0)?.toUpperCase() + values.demat_role?.slice(1)?.toLocaleLowerCase() || '') + "",
                  'employment Type': "" + (values.employment_type || '') + "",
                  'employment Status': "" + (values.employment_status || '') + "",
                  'date Of Joining': "" + (values.date_of_joining || '') + "",
                  'date Of Birth': "" + (values.date_of_birth || '') + "",

                }}
                action={{ route: "/updateprofile", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
          </Grid>
        </MDBox>

      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
