import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Button, Card, Chip, CircularProgress, Divider, Grid, IconButton, MenuItem, Select, StepContent, Typography, TableBody, Table, TableCell, TableRow, TableHead, TableContainer, Paper } from '@mui/material';
import { Column, DataGrid, Pager, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar, RequiredRule, NumericRule, RangeRule, StringLengthRule, Popup, Form, AsyncRule, HeaderFilter, ColumnHeaderFilter, FilterRow, Search, Scrolling } from 'devextreme-react/data-grid';
import { useState } from 'react';
import { useEffect } from 'react';
import Papa from "papaparse";
import MDBadge from 'components/MDBadge';
import { FileUploader } from 'react-drag-drop-files';
import { ArrowForward, East } from '@mui/icons-material';
import skipImage from "assets/images/import_from_excel_merge_skip.png";
import insertTask from "assets/images/import_from_excel_merge_create.png";
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { import_lead_bulk } from 'Apis/config/Url';
import Caller from 'Apis/config/Caller';
import { GET, POST } from 'Apis/config/RequestType';
import MDTypography from 'components/MDTypography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MapIcon from '@mui/icons-material/Map';
import DifferenceIcon from '@mui/icons-material/Difference';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import { get_error_import } from 'Apis/config/Url'

const steps = ['Upload File', 'Map the columns', 'Choose how to handle duplicates']
const leadColoumns = ['First Name', 'Last Name', 'Mobile', 'Product', 'Assigned To', 'City', 'Category']
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,#0b8793 0%,#0b8793 50%,#360033 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,#0b8793 0%,#0b8793 50%,#360033 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, #0b8793 0%, #0b8793 50%, #360033 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, #0b8793 0%, #0b8793 50%, #360033 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <UploadFileIcon />,
        2: <MapIcon />,
        3: <DifferenceIcon />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function BulkUpload(props) {
    const { enqueueSnackbar } = useSnackbar();
    let updateData = props.updateData ?? "";
    const fileTypes = ["csv", 'xls', 'xlsx'];
    const [activeStep, setActiveStep] = useState(0);
    const [stepLabelVisible, setStepLabelVisible] = useState(false);
    const [setBadgeContent, setSelectedBadgeContent] = useState("");
    const [reopenVisible, setReopenVisible] = useState(false);
    const [nextButtonDisabled, setnextButtonDisabled] = useState(true);

    const [importFileName, setImportFileName] = useState('')
    const [importRowCount, setImportRowCount] = useState(0)
    const [importErrorCount, setImportErrorCount] = useState(0)
    const [importLoader, setImportLoader] = useState(false)

    const [getErrorData, setErrorData] = useState([])



    const [file, setFile] = useState({
        file: null,
        fileHeader: [],
        file_name: "",
        fileFirstData: [],
        fileData: [],
        coloumnMap: [],
        selectedDuplicate: 1,
        loader: false
    });

    const handleChange = (file) => {

        setFile((values) => ({
            ...values,
            file: file,
            file_name: file.name
        }));

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);

            Papa.parse(csv, {
                skipEmptyLines: true,
                complete: function (results) {

                    let coloumnMap = []
                    for (let column in results.data[0]) {
                        let uploadedColoumnName = results.data[0][column].replace(/[^a-zA-Z ]/g, "").toLowerCase()
                        for (let leadColoumn in leadColoumns) {
                            let dbColoumnName = leadColoumns[leadColoumn].toLowerCase()
                            if (dbColoumnName == uploadedColoumnName) {
                                dbColoumnName = dbColoumnName.replace(" ", "_");
                                coloumnMap[column] = { columnIndex: column, columnName: dbColoumnName }
                            }
                        }

                    }

                    let fileDataN = [];
                    let i = 0;
                    results.data.map((Leadlabel, leadindex) => {
                        if (i != 0) {
                            fileDataN.push(Leadlabel)
                        }
                        i++;
                    });

                    setFile((values) => ({
                        ...values,
                        fileHeader: results.data[0],
                        fileFirstData: results.data[1],
                        fileData: fileDataN,
                        coloumnMap: coloumnMap
                    }));
                }
            });
        };

        reader.readAsArrayBuffer(file);

        if (activeStep == 0) {
            setnextButtonDisabled(false)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(-1);
        setReopenVisible(true);
    };

    const [isInitialRender, setIsInitialRender] = useState(true);

    // useEffect(() => {
    //     if (isInitialRender) {
    //         setIsInitialRender(false);
    //         setSelectedBadgeContent(updateData.lead_sub_status);
    //     }
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (isInitialRender) {
                let res = await Caller(get_error_import, '', GET, false, false);
                setErrorData(res.data)
                setImportFileName(res?.data[0]?.file_name)
                setImportRowCount(res?.data[0]?.total_rows_count)
                setImportErrorCount(res?.data[0]?.total_errors_count)
                setIsInitialRender(false);
                setSelectedBadgeContent(updateData.lead_sub_status);
            }
        };
        fetchData();
    }, []);


    const handleNext = async () => {
        if (file.fileFirstData) {
            if (activeStep == 2) {
                setImportLoader(true)

                let importFile = {
                    fileHeader: file.fileHeader, fileData: file.fileData, coloumnMap: file.coloumnMap
                    , fileName: file.file.name, skipItems: file.selectedDuplicate
                }

                setnextButtonDisabled(true)
                setFile((values) => ({
                    ...values,
                    loader: true
                }));

                await Caller(import_lead_bulk, importFile, POST, false, false).then((res) => {
                    setnextButtonDisabled(false);
                    setFile((values) => ({
                        ...values,
                        loader: false
                    }));
                    // props.onCloseBulk()
                    // if (res.success === true) {
                    //     setImportLoader(false)
                    //     props.onCloseBulk()
                    //     enqueueSnackbar('Leads Uploaded Successfully!', { variant: 'success', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    // } else {
                    //     enqueueSnackbar('Error Occur ! Please Check The File.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    // }

                    if (res.success === true) {
                        if (res.data) {
                            if (res?.data?.total_rows_count == res?.data?.total_errors_count) {
                                setImportLoader(false)
                                props.onCloseBulk()
                                enqueueSnackbar('Error Occur ! Please Check The File.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                            }
                            if (res?.data?.total_errors_count == 0) {
                                setImportLoader(false)
                                props.onCloseBulk()
                                enqueueSnackbar('Leads Uploaded Successfully!', { variant: 'success', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                            }
                            if ((res?.data?.total_errors_count != 0) && (res?.data?.total_rows_count != res?.data?.total_errors_count)) {
                                setImportLoader(false)
                                props.onCloseBulk()
                                enqueueSnackbar('Partially Leads Uploaded Successfully!', { variant: 'warning', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                            }
                        }
                        // setImportLoader(false)
                        // props.onCloseBulk()
                        // enqueueSnackbar('Leads Uploaded Successfully!', { variant: 'success', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    } else {
                        enqueueSnackbar('Error Occur ! Please Check The File.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                    }


                });
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            enqueueSnackbar('Excel has no data of leads !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
        }
    };

    const downloadFile = async (fileName) => {
        let a = document.createElement('a');
        a.href = './lead_template.xlsx';
        a.setAttribute(
            'download',
            fileName,
        );

        // Append to html link element page
        document.body.appendChild(a);

        // Start download
        a.click();

        // Clean up and remove the link
        a.parentNode.removeChild(a);

    };

    const onSelectColoumn = (event, value) => {
        let column = event.target.getAttribute('headerIndex');
        let dbColoumnName = event.target.value;
        let prevcoloumnMap = file.coloumnMap;
        dbColoumnName = dbColoumnName.replace(" ", "_");
        prevcoloumnMap[column] = { columnIndex: column, columnName: dbColoumnName }
        setFile((values) => ({
            ...values,
            coloumnMap: prevcoloumnMap
        }));
    }

    const selectDuplicate = (event) => {
        let selectIndex = event.target.getAttribute('selectIndex');
        setFile((values) => ({
            ...values,
            selectedDuplicate: selectIndex
        }));
    }


    const errorCheck = (data) => {
        if (data.data.errors_row_number == '' || data.data.errors_row_number == null) {
            return data.data.errors_row_number = '0'
        } else {
            return data.data.errors_row_number
        }
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    style={{
                        background: "white",
                        boxShadow: "none",
                        width: "100%",
                        paddingTop: "0px"
                    }}
                    connector={<ColorlibConnector />}
                >
                    {steps.map((label, index) => (
                        <Step
                            key={label}
                        >
                            <StepLabel
                                StepIconComponent={ColorlibStepIcon}

                            >{label}</StepLabel>

                        </Step>
                    ))}
                </Stepper>

                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep == 0 && <Box sx={{ p: 3 }}>
                            <IconButton color="success" aria-label="upload picture" component="label" onClick={(e) => downloadFile("Lead-Template")}>
                                <MDTypography variant="h6" style={{ color: "#0658c2" }}>Download Template</MDTypography> &nbsp;<DownloadForOfflineIcon />
                            </IconButton>
                            <Card sx={{ p: 2, height: "calc(100vh - 500px)", overflow: "auto" }}>
                                <FileUploader className="bulk-file-upload" handleChange={handleChange} name="file" multiple={false} types={fileTypes} />
                                {(file.file_name) && <Grid align="center" pt={2}>
                                    <MDTypography variant="h5">
                                        File Name:   {file.file_name}
                                    </MDTypography>
                                </Grid>}
                            </Card>
                        </Box>
                        }{activeStep == 1 && <Box sx={{ p: 3, color: "black" }}>
                            <Typography variant="h1" component="h2" sx={{ color: "black !important", fontWeight: "600 !important" }}>Map the columns from Excel
                                to the columns in this lead</Typography>
                            <Typography variant='h5' sx={{ mb: 2, color: "black !important", fontWeight: "300 !important" }}>Choose which column from the Excel file will appear as the item name (the first column in your lead)</Typography>

                            <Card sx={{ p: 2, height: "calc(100vh - 500px)", overflow: "auto" }}>
                                <div className='map-columns-mapper-titles'>
                                    <span className='map-columns-mapper-title'>Excel columns</span>
                                    <span className='map-columns-mapper-title'>Existing lead columns</span>
                                </div>
                                {file.fileHeader?.map((label, index) => (
                                    <div class="map-columns-mapper-content"><div class="map-columns-column-row">
                                        <div class="map-columns-column-item file-column">
                                            <span class="map-columns-column-row-name">{label}</span>
                                            <span class="map-columns-column-row-example">{file.fileFirstData ? file.fileFirstData[index] : ""}</span>
                                        </div>
                                        <East sx={{ flex: .75 }}></East>
                                        <div class="map-columns-column-item">
                                            <select className='map-data-dropdown' onChange={onSelectColoumn} headerIndex={index} >
                                                <option>Select</option>
                                                {leadColoumns?.map((Leadlabel, leadindex) => (
                                                    <option value={Leadlabel.toLowerCase()} selected={Leadlabel.toLowerCase() == label.replace(/[^a-zA-Z ]/g, "").toLowerCase()}>{Leadlabel}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    </div>
                                ))}

                            </Card>
                        </Box>
                        }

                        {activeStep == 2 && <Box sx={{ p: 3, color: "black" }}>
                            <Typography variant="h1" component="h2" sx={{ color: "black !important", fontWeight: "600 !important" }}>Choose how to handle duplicates</Typography>
                            <Typography variant='h5' sx={{ mb: 2, color: "black !important", fontWeight: "300 !important" }}>What should we do with any duplicated items?</Typography>

                            <Grid container sx={{ p: 2 }} columnSpacing={2} >
                                <Grid item xs={6} >
                                    <Box onClick={selectDuplicate} selectIndex={1} sx={{ textAlign: "center", border: (file.selectedDuplicate == 1 ? "1px solid black" : "1px solid #ddd"), borderRadius: "5px", p: 2, cursor: "pointer" }}>
                                        <img onClick={selectDuplicate} selectIndex={1} src={skipImage} />
                                        <Typography onClick={selectDuplicate} selectIndex={1} variant="h3" sx={{ pt: 2, color: "black !important", fontWeight: "600 !important" }}>Skip Items</Typography>
                                        <Typography onClick={selectDuplicate} selectIndex={1} variant="h5" sx={{ color: "black !important", fontWeight: "300 !important" }}>Don't add a lead if product and mobile number matches</Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box onClick={selectDuplicate} selectIndex={2} sx={{ textAlign: "center", border: (file.selectedDuplicate == 2 ? "1px solid black" : "1px solid #ddd"), borderRadius: "5px", p: 2, cursor: "pointer" }}>
                                        <img onClick={selectDuplicate} selectIndex={2} src={insertTask} />
                                        <Typography onClick={selectDuplicate} selectIndex={2} variant="h3" sx={{ pt: 2, color: "black !important", fontWeight: "600 !important" }}>Add as Task</Typography>
                                        <Typography onClick={selectDuplicate} selectIndex={2} variant="h5" sx={{ color: "black !important", fontWeight: "300 !important" }}>Add task to lead if product and mobile number matches</Typography>

                                    </Box>
                                </Grid>
                                {importLoader && <Box
                                    sx={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        zIndex: 9999,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        flexDirection: 'column',
                                        padding: '20px',
                                        color: 'black',
                                    }}
                                >
                                    <CircularProgress size={80} /> {/* Adjust the size value as needed */}
                                    <Typography variant="body1" sx={{ marginTop: '10px', color: '#ededed' }}>Please wait, your data is importing...</Typography>
                                </Box>}



                            </Grid>

                        </Box>}

                        {activeStep == 0 && <Typography variant="h4" sx={{ marginLeft: 3 }}>Lead Import Logs</Typography>}

                        {activeStep == 0 && <MDBox p={3} display="flex" justifyContent="center" sx={{ height: '300px' }}>

                            <DataGrid
                                id="gridContainer"
                                keyExpr="id"
                                dataSource={getErrorData}
                                showBorders={true}
                                remoteOperations={true}
                                wordWrapEnabled={true}
                                showColumnLines={false}
                                rowAlternationEnabled={true}
                                allowColumnReordering={true}
                                columnAutoWidth={true} // Enable automatic column width
                                width="700px" // Set a fixed width for the DataGrid container
                                sx={{ marginLeft: 40, overflowX: 'auto' }} // Ensure horizontal scrolling is enabled
                            >
                                <Pager
                                    visible={true}
                                    displayMode="full"
                                    showInfo={true}
                                    allowedPageSizes={'all'}
                                />
                                <Paging defaultPageSize={3} />

                                <Column
                                    caption="File Name"
                                    dataField="file_name"
                                    alignment="center"
                                    width={150} // Set the desired width
                                />
                                <Column
                                    caption="Total Row Count"
                                    dataField="total_rows_count"
                                    alignment="center"
                                    width={150} // Set the desired width
                                />
                                <Column
                                    caption="Total Error Count"
                                    dataField="total_errors_count"
                                    alignment="center"
                                    width={150} // Set the desired width
                                />
                                <Column
                                    caption="Imported Lead Count"
                                    dataField="imported_count"
                                    alignment="center"
                                    width={150} // Set the desired width
                                />
                                <Column
                                    caption="Row Number"
                                    dataField="errors_row_number"
                                    alignment="center"
                                    cellRender={errorCheck}
                                    width={100} // Set the desired width
                                />
                                <Column
                                    caption="Upload Time"
                                    dataField="uploaded_at"
                                    alignment="center"
                                    width={150} // Set the desired width
                                />
                            </DataGrid>


                        </MDBox>}



                        {<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />


                            <MDButton color='primary' onClick={handleNext} disabled={nextButtonDisabled}>
                                {activeStep === steps.length - 1 ? (file.loader ? 'Importing...' : 'Import file') : 'Next'}
                            </MDButton>
                        </Box>}
                    </React.Fragment>
                )}
            </Box>

        </>
    );
}