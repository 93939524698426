/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

import SendPaymentLink from './send-payment-link';

import DeletePopup from "layouts/master-data/delete-popup";
import Caller from 'Apis/config/Caller';
import { GET } from 'Apis/config/RequestType';
import CustomStore from 'devextreme/data/custom_store';
import { POST } from 'Apis/config/RequestType';
import { change_lookup_status } from 'Apis/config/Url';
import { delete_related_type_designation } from 'Apis/config/Url';
import Footer from 'examples/Footer';

import { getPaymentsData } from 'Apis/Auth/auth';

import moment from 'moment';
// moment.tz.setDefault("Asia/Kolkata");

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
}


/// payments screen function.
const payments = (props) => {
  const paymentsData = new CustomStore({

    // key: 'id',
    load(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
        "isLoadingAll"
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {

          if (i === "skip") {
            let page = (loadOptions[i] + 10) / 10;
            if (page !== 1) {
              params += `page=${page}&`;
            }
          } else if (i === "sort") {
            let sortType = loadOptions[i][0]["desc"] ? "desc" : "asc";
            let sortColoumn = loadOptions[i][0]["selector"];
            params += `sortType=${sortType}&sortColoumn=${sortColoumn}&`;
          } else if (i === "filter") {
            let filterO = loadOptions[i][0]["filterValue"];
            params += `s=${filterO}&`;
          } else {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
        }
      });
      params = params.slice(0, -1);
      // return getPaymentsData(params)
      return getPaymentsData(params + "&id=" + props.updateData.id.substring(3))
        .then((data) => ({
          data: data.data.data,
          totalCount: data.data.count,
          next: data.data.next,
          previous: data.data.previous

        }));
    },

  });

  const firstPath = location.pathname.split('/')[1];

  const userGrid = useRef();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [popuptitle, setPopupTitle] = useState("Add");
  const [updateData, setUpdate] = useState("");
  const popupDeleteText = firstPath == 'departments' ? "Do you want to delete this Department data?" : "Do you want to delete this Designation data?";

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUpdate("");
      setOpen(false);
      setDeleteOpen(false);
    }
  };

  const selectPopupVisible = async (e) => {
    setUpdate(e.row.data);
    setOpen(true)
    switch (e) {
      case "edit":
        setOpen(true)
    }
    // await Caller(get_related_type_designation + "?id=" + e.row.data.id + "&page=1&s=&take=10", "", GET, false, true).then(async (res) => {
    //   if (res.success === true) {
    // setUpdate(res.data.data);
    // }
    // });
    setPopupTitle("Update Call");
    setOpen(true);
  }

  const deleteVisible = (e) => {
    setUpdate(e.row.data.id);
    setDeleteOpen(true);
  }

  const refreshGrid = () => {
    userGrid.current.instance.refresh();
  }
  const refreshGridDelete = () => {
    userGrid.current.instance.refresh();
  }

  const handleChanged = async (event, id) => {
    let current_id = id;
    let is_active = "";
    if (event.target.checked === false) {
      is_active = 0
    }
    else {
      is_active = 1
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", is_active);
    await Caller(change_lookup_status, formData, POST, false, true)
      .then((data) => {
        if (data.success === true) {
          refreshGrid();
        }
      });
  }


  // is payment link send Grid
  const is_payment_link_sendGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_payment_link_send == 1 || data.data.is_payment_link_send == true) {
      return "Yes"
    }
    else {
      return "No"
    }
  }

  // is payment done Grid
  const isPaymentDoneGridStatus = (data) => {
    let func = "";
    let label = "";
    if (data.data.is_payment_done === '1' || data.data.is_payment_done == true) {
      // return "Paid"
      return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #0f9b0f 10%, #799F0C 360%)' }} variant="subtitle1">Paid</MDTypography>;
    } else if (data.data.is_payment_done === '2') {
      // return "Failed"
      return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #F44335 10%, #FF512F 360%)' }} variant="subtitle1">Failed</MDTypography>;
    } else {
      return <MDTypography style={{ color: 'white', background: 'linear-gradient(195deg, #DD2476 10%, #FF512F 360%)' }} variant="subtitle1">No</MDTypography>;
    }
  }


  /* Format null value to empty  */
  const null_to_empty = (data) => {
    if (data.value == 'null') {
      return "";
    } else {
      return data.value;
    }
  }

  // paymentLinkSendTime Grid
  const paymentLinkSendTime = (data) => {
    // return <div> {moment(data.data.payment_link_sent_time).format('DD/MM/YYYY hh:mm:ss')} </div>
    return <div> {moment(data.data.payment_link_sent_time).utcOffset("+05:30").format('MM/DD/YYYY, h:m A')} </div>;
  }

  // payment_done_time
  const paymentDoneTime = (data) => {

    if (data.data.payment_done_time == null || data.data.payment_done_time == '') {
      return <div> {""} </div>
    } else {
      // return <div> {moment(data.data.payment_done_time).format('DD/MM/YYYY hh:mm:ss')} </div>
      return <div> {moment(data.data.payment_link_sent_time).utcOffset("+05:30").format('MM/DD/YYYY, h:m A')} </div>;
    }
  }

  // created_at Grid
  const createdAt = (data) => {
    // return <div> {moment(data.data.created_at).format('DD/MM/YYYY hh:mm:ss')} </div>
    return <div> {moment(data.data.payment_link_sent_time).utcOffset("+05:30").format('MM/DD/YYYY, h:m A')} </div>;
  }

  const renderCallData = (data) => {
    return <a href="#" onClick={() => selectPopupVisible(data)}>{data.data.name}</a>
  }


  // const created_by_name = (data) => {
  //   if (data.data.created_by_name == null) {
  //     return "Admin"
  //   } else {
  //     return data.data.created_by_name
  //   }
  // }



  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sx'
            sx={{
              '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
            }}
          >
            <DialogTitle
            ><MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              opacity={1}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            // textAlign="center"
            >
                <MDTypography variant="h4" color="white">{popuptitle}</MDTypography>
                <IconButton
                  style={{ color: "white", cursor: "pointer" }}
                  sx={{
                    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                  }}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </MDBox></DialogTitle>
            <DialogContent p={3}>
              <SendPaymentLink updateData={props.updateData} close={handleClose} refresh={refreshGrid} />
            </DialogContent>
          </Dialog>
          <MDBox p={3}>
            <DataGrid
              id="gridContainer"
              dataSource={paymentsData}
              keyExpr="id"
              ref={userGrid}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              showColumnLines={false}
              rowAlternationEnabled={true}
            >
              <Pager
                visible={true}
                displayMode="full"
                showInfo={true}
                showNavigationButtons={true} />
              <Paging defaultPageSize={10} />
              <SearchPanel
                visible={true}
              />
              <Export
                enabled={true}
                fileName="Payment List"
              />
              {/* <Editing
                    mode="row"
                    allowAdding={false}
                    allowUpdating={true}
                    useIcons={true}
                  /> */}

              <Column
                caption="Payment ID"
                dataField="payment_id"
                alignment="center"
                minWidth={100}
              />

              {/* <Column
                caption="Merchant ID"
                dataField="merchant_id"
                alignment="center"
                minWidth={100}
              />

              <Column
                caption="Transaction ID"
                dataField="transaction_id"
                alignment="center"
                minWidth={100}
              /> */}

              <Column
                caption="Payment Amount"
                dataField="payment_amount"
                alignment="center"
                minWidth={150}
              />

              <Column
                caption="Payment Link Send Time"
                dataField="payment_link_sent_time"
                alignment="center"
                minWidth={200}
                // cellRender={null_to_empty}
                // cellRender={paymentLinkSendTime}
                dataType="datetime"
                format="MM/dd/yyyy, hh:m a"

              />

              <Column
                caption="Is Payment Done"
                dataField="is_payment_done"
                alignment="center"
                minWidth={150}
                cellRender={isPaymentDoneGridStatus}
              />

              <Column
                caption="Payment Done Time"
                dataField="payment_done_time"
                alignment="center"
                minWidth={150}
                // cellRender={paymentDoneTime}
                // dataType="datetime"
                // cellRender={null_to_empty}
                dataType="datetime"
                format="MM/dd/yyyy, hh:m a"

              />

              <Column
                caption="Created At"
                dataField="created_at"
                alignment="center"
                minWidth={100}
                // cellRender={createdAt}
                // dataType="datetime"
                dataType="datetime"
                format="MM/dd/yyyy, hh:m a"

              />

              <Column
                caption="Created By"
                dataField="created_by_name"
                alignment="center"
                // cellRender={created_by_name}
                minWidth={100}
              />

              {/* <Column type="buttons" dataField="Actions" minWidth={150}> */}
              {/* <Button icon="edit" hint="Edit" onClick={(e) => selectPopupVisible(e)} /> */}
              {/* <Button hint="Delete" icon="trash" style={{ color: "red" }} onClick={deleteVisible} /> */}
              {/* </Column> */}
              <Toolbar>
                <Item name="searchPanel" locateInMenu="auto" />
                <Item name="exportButton" locateInMenu="auto" />
                <Item location="after" locateInMenu="auto">
                  {/* <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle("Add Calls"); }}> */}
                  <MDButton variant="gradient" color="success" onClick={() => { setOpen(true); setUpdate(""); setPopupTitle("Send Payment Link"); }}>
                    {/* Add&nbsp; */}
                    Send Payment Link&nbsp;
                    {/* <Icon>add</Icon>&nbsp; */}
                  </MDButton>
                </Item>
              </Toolbar>

            </DataGrid>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  )
}

export default payments