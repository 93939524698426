import React, { useState } from 'react'
import { Grid, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import MDButton from 'components/MDButton';
import AddIcon from '@mui/icons-material/Add';
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { lead_meeting } from 'Apis/config/Url';
import { useEffect } from 'react';
import { PUT } from 'Apis/config/RequestType';
import { memo } from 'react';
import { TINYMCE_KEY } from 'Apis/config/ResponseHandler';

const Meeting = memo(props => {
    let todayDate = new Date();
    const [date_time, setDateTimeValue] = useState(todayDate.toISOString().substring(0, 16));
    const [disabledSave, setDisabledSave] = useState();

    const handleDateChange = (event) => {
        setDateTimeValue(event.target.value);
    };

    const [editorValues, setEditorValues] = useState({
        body: ""
    })

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            if (props.editData) {
                setDateTimeValue(props.editData.scheduled_time_date ? props.editData.scheduled_time_date.substring(0, 16) : todayDate.toISOString().substring(0, 16));
                setEditorValues((values) => ({
                    ...values,
                    body: props.editData.description ?? ""
                }))
            }
        }
    }, [props])

    const textValue = (content, editor) => {
        let re = /^.{0,1000}$/;
        if (content.match(re) || content === '') {
            setEditorValues((state) => ({
                ...state, body: content
            }));
        }
    }

    const submitMeeting = async (event) => {
        event.target.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
        setDisabledSave('disabled');
        if (!event.target.checkValidity()) {
            setDisabledSave('');
            return false;
        }
        const formData = new FormData();
        { (!props.editData) && formData.append('lead_id', props.updateData.id.substring(3)); }
        formData.append('meeting_date', date_time);
        formData.append('meeting_description', editorValues.body);
        await Caller(props.editData ? lead_meeting + "?id=" + props.editData.type_id : lead_meeting, formData, props.editData ? PUT : POST, false, true).then((res) => {
            if (res.success === true) {
                props.refresh();
                props.close();
            }

        })
    }

    const allTrue = () => {
        if (date_time && editorValues.body) {
            return false
        } else {
            return true
        }
    }

    return (
        <Grid container >
            <Grid
                item
                xs={12}
                sx={{
                    paddingBottom: 1,
                }}
            >
                <TextField
                    id="datetime-local"
                    label="Date & Time"
                    type="datetime-local"
                    value={date_time}
                    onChange={handleDateChange}
                    sx={{ width: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                <Editor
                    id="validationCustom03"
                    apiKey={TINYMCE_KEY}
                    required
                    name="body"
                    value={editorValues.body}
                    init={{
                        height: 350,
                        plugins: "image code",
                        directionality: "ltr",
                        menubar: false,
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | " +
                            "bullist numlist"
                    }}

                    onEditorChange={textValue}
                />
            </Grid>
            <Grid
                item
                xs={5}
                sx={{
                    paddingTop: 1,
                }}
            >
                <MDButton startIcon={<AddIcon />} color='info' className={`action-button ${disabledSave}`} size="small" onClick={submitMeeting} disabled={allTrue()}>{props.editData ? "Update" : "Add"} Meeting</MDButton>
            </Grid>
        </Grid>


    )
})

export default Meeting