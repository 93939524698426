/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import Caller from "Apis/config/Caller";
import { PUT } from "Apis/config/RequestType";
import { GET, POST } from "Apis/config/RequestType";
import { get_related_type_designation } from "Apis/config/Url";
import { update_lookup } from "Apis/config/Url";
import { add_calls } from "Apis/config/Url";
import { edit_calls } from "Apis/config/Url";

import { expiry_date } from "Apis/config/Url";
import { get_symbols } from "Apis/config/Url";
import { getSymbols } from 'Apis/Auth/auth';

import { send_payment_link } from "Apis/config/Url";
// import { generatePhonepeOrderLink } from "Apis/config/Url";
import { get_lead_employees_name } from 'Apis/config/Url';

import { lead_activity } from 'Apis/config/Url';




import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import "./styles.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "10vw",
    },
  },
};

/// whenever, there will be a need to reset the value. simply, It can be called. 
const initialSetAllValues = {
  name: "",
  email: "",
  contact: "",
  amount: "",
  description: "",
  // created_at: "",
  // updated_at: "",
  // checkExpiryDisabled: false
}

const SendPaymentLink = (props) => {
  const [visible, setVisible] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [disabledSave, setDisabledSave] = useState();
  const [values, setAllValues] = useState(initialSetAllValues);

  const [errors, setAllErrors] = useState({

    name: "",
    email: "",
    contact: "",
    amount: "",
    description: "",
    // created_at: "",
    // updated_at: "",
  });

  const textUpdate = (e) => {
    // const re = /^[a-zA-Z0-9 ]+$/;
    const re = /^[a-zA-Z ]*$/;
    if (re.test(e.target.value) || e.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: "",
    }));
  };

  const descriptionUpdate = (e) => {
    const re = /^[ A-Za-z0-9_@./#&+-,]*$/;

    if (re.test(e.target.value) || e.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: "",
    }));
  };

  const mailUpdate = (e) => {
    // const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


    if (re.test(e.target.value) || e.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${e.target.name}`]: e.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${e.target.name}`]: "",
    }));
  };

  const digitsUpdate = (f) => {
    const re = /^\d+$/;   //original
    // const re = /^\d{1,}(\.\d{0,2})?$/;
    var charLength = f.target.value.length;
    if (re.test(f.target.value) || f.target.value === "") {
      setAllValues((state) => ({
        ...state,
        [`${f.target.name}`]: f.target.value,
      }));
    }
    setAllErrors((values) => ({
      ...values,
      [`${f.target.name}`]: "",
    }));
  };


  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      if (props.updateData) {
        setAllValues((values) => ({
          ...values,
          // name: props.updateData[0].name,
          // description: props.updateData[0].description
          name: props.updateData.first_name + " " + (props.updateData.last_name == null ? '' : props.updateData.last_name),
          email: props.updateData.email == '' || props.updateData.email == null ? '' : props.updateData.email,
          contact: props.updateData.mobile,

          // is_app: props.updateData.is_app === 1 ? "true" : "false",

        }));
      }
    }
  }, []);

  /// Sending response
  const addLookup = async (event) => {
    event.target.classList.add("was-validated");
    setDisabledSave("disabled");
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("contact", values.contact);
    formData.append("amount", values.amount);
    formData.append("description", values.description);

    await Caller(send_payment_link + "?lead_id=" + props.updateData.id.substring(3), formData, POST, false, true)
      .then((res) => {
        if (res.success === true) {
          enqueueSnackbar("Payment Link Sent Successfully.", {
            variant: "success",
            autoHideDuration: 2000,
            TransitionProps: { direction: "left" },
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
          props.refresh();
          props.close();
        } else if (res.success === false) {
          setDisabledSave("");
          for (const [key, value] of Object.entries(res.message["errors"])) {
            setAllErrors((values) => ({
              ...values,
              [`${key}`]: value,
            }));
          }
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.message));
      });

    // await Caller(generatePhonepeOrderLink + "?lead_id=" + props.updateData.id, formData, POST, false, true)
    //   .then((res) => {
    //     if (res.success === true) {
    //       enqueueSnackbar("Payment Link Sent Successfully.", {
    //         variant: "success",
    //         autoHideDuration: 2000,
    //         TransitionProps: { direction: "left" },
    //         anchorOrigin: { horizontal: "right", vertical: "top" },
    //       });
    //       props.refresh();
    //       props.close();
    //     } else if (res.success === false) {
    //       setDisabledSave("");
    //       for (const [key, value] of Object.entries(res.message["errors"])) {
    //         setAllErrors((values) => ({
    //           ...values,
    //           [`${key}`]: value,
    //         }));
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     alert(JSON.stringify(error.message));
    //   });

  };

  /// validating all required fields
  const allTrue = () => {
    if (
      values.name !== "" &&
      // values.email !== "" &&
      values.amount !== "" &&
      values.description !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };


  return (
    <Box
      component="form"
      onSubmit={addLookup}
      sx={{
        width: 700,
        height: 340,

        "#demo-row-radio-buttons-group-label": { fontSize: "medium", textAlign: "left" },
        ".role-select-custom": { width: "100%" },
        ".add-label": { pb: 1 },
        ".radio-button-custom": { fontSize: "small" },
      }}
      noValidate
    >

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {/* <Grid item xs={2} sm={4} md={4}> */}
        <Grid
          item
          xs={2}
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Name :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>

          <TextField
            required
            id="outlined-required"
            name="name"
            disabled={true}
            // type="text"
            value={values.name}
            error={errors.name}
            helperText={errors.name === "" ? "" : errors.name}
            style={{ width: "60%" }}
            // onFocus={(e) =>
            //   e.target.addEventListener(
            //     "wheel",
            //     function (e) {
            //       e.preventDefault();
            //     },
            //     { passive: false }
            //   )
            // }
            inputProps={{ maxLength: 50 }}
            onChange={textUpdate}
          />
        </Grid>


        <Grid
          item
          xs={2}
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Email :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="email"
            disabled={true}
            // type="email"
            value={values.email}
            error={errors.email}
            helperText={errors.email === "" ? "" : errors.email}
            style={{ width: "60%" }}
            // onFocus={(e) =>
            //   e.target.addEventListener(
            //     "wheel",
            //     function (e) {
            //       e.preventDefault();
            //     },
            //     { passive: false }
            //   )
            // }
            inputProps={{ maxLength: 150 }}
            onChange={mailUpdate}
          />
        </Grid>

        <Grid
          item
          xs={2}
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Mobile :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="contact"
            disabled={true}

            value={values.contact}
            error={errors.contact}
            helperText={errors.contact === "" ? "" : errors.contact}
            style={{ width: "60%" }}
            // onFocus={(e) =>
            //   e.target.addEventListener(
            //     "wheel",
            //     function (e) {
            //       e.preventDefault();
            //     },
            //     { passive: false }
            //   )
            // }
            inputProps={{ maxLength: 150 }}
            onChange={mailUpdate}
          />
        </Grid>


        <Grid
          item
          xs={2}
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Amount :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="amount"
            // type="number"
            value={values.amount}
            error={errors.amount}
            helperText={errors.amount === "" ? "" : errors.amount}
            style={{
              width: "60%",
              overflow: "hidden",
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            inputProps={{ maxLength: 9 }}
            onChange={digitsUpdate}
          />
        </Grid>


        <Grid
          item
          xs={2}
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel className="add-label">
            Description :<sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <TextField
            required
            id="outlined-required"
            name="description"
            // type="number"


            value={values.description}
            error={errors.description}
            helperText={errors.description === "" ? "" : errors.description}
            style={{
              width: "60%",
              overflow: "hidden",
            }}

            multiline
            rows={4}

            // onFocus={(e) =>
            //   e.target.addEventListener(
            //     "wheel",
            //     function (e) {
            //       e.preventDefault();
            //     },
            //     { passive: false }
            //   )
            // }
            inputProps={{ maxLength: 200 }}
            onChange={descriptionUpdate}
          />
        </Grid>

        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            marginTop: "2%",
            marginBottom: "0%",
            width: "104%",
            // borderRadius: "0.5rem",   //original
            borderRadius: "0.5rem",
            transform: "translateY(35%)",
            ".action-button": { width: 100 },
          }}
        >
          <MDButton
            color="success"
            className={`action-button ${disabledSave}`}
            type="submit"
            disabled={allTrue()}
          >
            {/* {props.updateData ? "Update" : "Add"} */}
            {props.updateData ? "Send" : "Add"}
          </MDButton>
          <MDButton color="warning" className="action-button" onClick={props.close}>
            Cancel
          </MDButton>
        </DialogActions>
      </Grid>
    </Box>
  );
};

export default SendPaymentLink;
